import React, { useCallback } from 'react';
import s from './BookingRequestPage.module.sass';
import BookingHeader from './components/BookingHeader';
import { BookingContextProvider, useBookingContext } from './context';
import Appointment from './steps/Appointment';
import PricingLevel from './steps/PricingLevel';
import ContactInformation from './steps/ContactInformation';
import Completed from './steps/Completed';
import { useCreateBookingRequestMutation } from 'graphql/mutations/bookingRequest/generated/CreateBookingRequest';
import { BookingRequestDataTransformer } from 'components/app/bookingRequest';
import { onSubmitBookingRequest } from './BookingRequestPage.type';
import TargetGroup from './steps/TargetGroup';
import Loading from 'components/common/loading/Loading';
import { useTargetGroupsForBookingRequestFormQuery } from 'graphql/queries/targetGroup/generated/TargetGroupsForBookingRequestForm';
import AlertGraphQLError from 'components/common/AlertGraphQLError';

const steps: FC[] = [TargetGroup, Appointment, PricingLevel, ContactInformation];

const BookingRequestContent = () => {
  const { currentStep: C } = useBookingContext();
  return <div className="bg-light p-3">{C ? <C /> : null}</div>;
};

const useBookingRequest = () => {
  const [createBookingRequest] = useCreateBookingRequestMutation();
  return useCallback<onSubmitBookingRequest>(
    (values, formConfig) => {
      return createBookingRequest({
        variables: {
          data: BookingRequestDataTransformer.transformFormValuesToGraphQLParams(values, formConfig),
        },
      });
    },
    [createBookingRequest],
  );
};

const BookingRequestLayout = () => {
  const createBookingRequest = useBookingRequest();
  const { data, loading, error } = useTargetGroupsForBookingRequestFormQuery();

  if (error) return <AlertGraphQLError error={error} />;
  if (!data && loading) return <Loading size={60} position="center" />;
  return (
    <BookingContextProvider
      steps={steps}
      completed={Completed}
      onComplete={createBookingRequest}
      targetGroups={data?.targetGroups}
    >
      <div className={s.layout}>
        <BookingHeader />
        <BookingRequestContent />
      </div>
    </BookingContextProvider>
  );
};

export default BookingRequestLayout;
