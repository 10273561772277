import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { AppointmentFragmentDoc } from '../../../fragments/appointments/generated/appointmentFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateBookingRequestAppointmentMutationVariables = SchemaTypes.Exact<{
  appointmentId: SchemaTypes.Scalars['ID'];
  data: SchemaTypes.BookingRequestAppointmentPartialInput;
}>;

export type UpdateBookingRequestAppointmentMutation = {
  __typename?: 'Mutation';
  updateBookingRequestAppointment: {
    __typename?: 'Appointment';
    id: string;
    ageOfParticipants?: string | null;
    code?: string | null;
    endDate: string | Date;
    guideCheckInDate?: string | Date | null;
    infoForGuides?: string | null;
    infoForOffice?: SchemaTypes.AppointmentInfoForOffice | null;
    startDate: string | Date;
    status: SchemaTypes.AppointmentStatus;
    totalAccompanyingPersons?: number | null;
    totalGuides?: number | null;
    totalParticipants?: number | null;
    totalSlots: number;
    type: SchemaTypes.AppointmentType;
    bookingkitConnection?: {
      __typename?: 'AppointmentBookingkitConnection';
      dateId?: string | null;
      orderCode?: string | null;
      dateSyncedAt?: string | Date | null;
      dateSyncSuccess?: boolean | null;
      orderSuccess?: boolean | null;
      orderSyncedAt?: string | Date | null;
      errorName?: string | null;
      errorMessage?: string | null;
      ignoreError?: boolean | null;
    } | null;
    bookingRequest?: { __typename?: 'BookingRequest'; id: string } | null;
    customer?: {
      __typename?: 'Customer';
      id: string;
      salutation: SchemaTypes.Salutation;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      email: string;
      organization: { __typename?: 'CustomerOrganization'; id: string; name?: string | null };
    } | null;
    guide?: { __typename?: 'User'; id: string; profile: { __typename?: 'UserProfile'; fullName: string } } | null;
    guideFeedback?: {
      __typename?: 'GuideFeedback';
      totalParticipants: number;
      totalAccompanyingPersons?: number | null;
      comment: string;
      createdAt: string | Date;
    } | null;
    product?: {
      __typename?: 'Product';
      id: string;
      name: string;
      meetingPointName?: string | null;
      bookingkitSyncEnabled: boolean;
    } | null;
    tourTopic?: { __typename?: 'TourTopic'; id: string; backgroundColor: string } | null;
  };
};

export const UpdateBookingRequestAppointmentDocument = gql`
  mutation UpdateBookingRequestAppointment($appointmentId: ID!, $data: BookingRequestAppointmentPartialInput!) {
    updateBookingRequestAppointment(appointmentId: $appointmentId, data: $data) {
      ...Appointment
    }
  }
  ${AppointmentFragmentDoc}
`;
export type UpdateBookingRequestAppointmentMutationFn = Apollo.MutationFunction<
  UpdateBookingRequestAppointmentMutation,
  UpdateBookingRequestAppointmentMutationVariables
>;

/**
 * __useUpdateBookingRequestAppointmentMutation__
 *
 * To run a mutation, you first call `useUpdateBookingRequestAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingRequestAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingRequestAppointmentMutation, { data, loading, error }] = useUpdateBookingRequestAppointmentMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateBookingRequestAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBookingRequestAppointmentMutation,
    UpdateBookingRequestAppointmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBookingRequestAppointmentMutation, UpdateBookingRequestAppointmentMutationVariables>(
    UpdateBookingRequestAppointmentDocument,
    options,
  );
}
export type UpdateBookingRequestAppointmentMutationHookResult = ReturnType<
  typeof useUpdateBookingRequestAppointmentMutation
>;
export type UpdateBookingRequestAppointmentMutationResult =
  Apollo.MutationResult<UpdateBookingRequestAppointmentMutation>;
export type UpdateBookingRequestAppointmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateBookingRequestAppointmentMutation,
  UpdateBookingRequestAppointmentMutationVariables
>;
