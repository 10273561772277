import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { BookingkitConnectionForAppointmentFragmentDoc } from '../../../fragments/bookingkit/generated/BookingkitConnectionForAppointmentFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePrivateTourAppointmentsStartDateMutationVariables = SchemaTypes.Exact<{
  bookingRequestId: SchemaTypes.Scalars['ID'];
  data: SchemaTypes.BookingRequestAppointmentPartialInput;
}>;

export type UpdatePrivateTourAppointmentsStartDateMutation = {
  __typename?: 'Mutation';
  updateBookingRequestAppointments: Array<{
    __typename?: 'Appointment';
    id: string;
    startDate: string | Date;
    endDate: string | Date;
    bookingkitConnection?: {
      __typename?: 'AppointmentBookingkitConnection';
      dateId?: string | null;
      orderCode?: string | null;
      dateSyncedAt?: string | Date | null;
      dateSyncSuccess?: boolean | null;
      orderSuccess?: boolean | null;
      orderSyncedAt?: string | Date | null;
      errorName?: string | null;
      errorMessage?: string | null;
      ignoreError?: boolean | null;
    } | null;
  }>;
};

export const UpdatePrivateTourAppointmentsStartDateDocument = gql`
  mutation UpdatePrivateTourAppointmentsStartDate(
    $bookingRequestId: ID!
    $data: BookingRequestAppointmentPartialInput!
  ) {
    updateBookingRequestAppointments(bookingRequestId: $bookingRequestId, data: $data) {
      id
      startDate
      endDate
      bookingkitConnection {
        ...BookingkitConnectionForAppointment
      }
    }
  }
  ${BookingkitConnectionForAppointmentFragmentDoc}
`;
export type UpdatePrivateTourAppointmentsStartDateMutationFn = Apollo.MutationFunction<
  UpdatePrivateTourAppointmentsStartDateMutation,
  UpdatePrivateTourAppointmentsStartDateMutationVariables
>;

/**
 * __useUpdatePrivateTourAppointmentsStartDateMutation__
 *
 * To run a mutation, you first call `useUpdatePrivateTourAppointmentsStartDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrivateTourAppointmentsStartDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrivateTourAppointmentsStartDateMutation, { data, loading, error }] = useUpdatePrivateTourAppointmentsStartDateMutation({
 *   variables: {
 *      bookingRequestId: // value for 'bookingRequestId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePrivateTourAppointmentsStartDateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePrivateTourAppointmentsStartDateMutation,
    UpdatePrivateTourAppointmentsStartDateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePrivateTourAppointmentsStartDateMutation,
    UpdatePrivateTourAppointmentsStartDateMutationVariables
  >(UpdatePrivateTourAppointmentsStartDateDocument, options);
}
export type UpdatePrivateTourAppointmentsStartDateMutationHookResult = ReturnType<
  typeof useUpdatePrivateTourAppointmentsStartDateMutation
>;
export type UpdatePrivateTourAppointmentsStartDateMutationResult =
  Apollo.MutationResult<UpdatePrivateTourAppointmentsStartDateMutation>;
export type UpdatePrivateTourAppointmentsStartDateMutationOptions = Apollo.BaseMutationOptions<
  UpdatePrivateTourAppointmentsStartDateMutation,
  UpdatePrivateTourAppointmentsStartDateMutationVariables
>;
