import React, { useMemo } from 'react';
import { CustomContainerProps, InputModalConfirm } from 'components/common/Modal/InputModal';
import i18n from 'i18n';
import Button from 'components/common/Button';
import { Save } from 'react-bootstrap-icons';
import * as yup from 'yup';
import { AppointmentForm, appointmentSchema } from 'components/app/appointment';
import { InternalRefetchQueryDescriptor } from '@apollo/client';
import { AppointmentSchemaProps } from 'components/app/appointment/AppointmentForm';
import { FormConfigs } from 'components/bookingRequest/utils';
import { customerSchema } from 'components/app/bookingRequest';
import { screenSizeConfig } from 'components/common/Visibility';
import {
  AppointmentModalProps,
  InitialValues,
  produceBookingSlotInput,
  useProduceData,
  produceEventInput,
  producePrivateTourInput,
  produceRecurringEventInput,
} from './utils';
import { AppointmentTypes } from 'constants/appointment';
import { toast } from 'react-toastify';
import client from 'graphql/apollo';
import {
  CreateBookingSlotAppointmentDocument,
  CreateBookingSlotAppointmentMutation,
  CreateBookingSlotAppointmentMutationVariables,
} from 'graphql/mutations/appointment/generated/CreateBookingSlotAppointment';
import {
  AddAppointmentToBookingRequestDocument,
  AddAppointmentToBookingRequestMutation,
  AddAppointmentToBookingRequestMutationVariables,
} from 'graphql/mutations/appointment/generated/AddAppointmentToBookingRequest';
import {
  UpdateBookingSlotAppointmentDocument,
  UpdateBookingSlotAppointmentMutation,
  UpdateBookingSlotAppointmentMutationVariables,
} from 'graphql/mutations/appointment/generated/UpdateBookingSlotAppointment';
import {
  UpdateBookingRequestAppointmentDocument,
  UpdateBookingRequestAppointmentMutation,
  UpdateBookingRequestAppointmentMutationVariables,
} from 'graphql/mutations/appointment/generated/UpdateBookingRequestAppointment';
import {
  CreateRecurringEventDocument,
  CreateRecurringEventMutation,
  CreateRecurringEventMutationVariables,
} from 'graphql/mutations/appointment/generated/CreateRecurringEvent';
import {
  CreateEventAppointmentDocument,
  CreateEventAppointmentMutation,
  CreateEventAppointmentMutationVariables,
} from 'graphql/mutations/appointment/generated/CreateEventAppointment';
import {
  UpdateEventAppointmentDocument,
  UpdateEventAppointmentMutation,
  UpdateEventAppointmentMutationVariables,
} from 'graphql/mutations/appointment/generated/UpdateEventAppointment';
import AlertGraphQLError from 'components/common/AlertGraphQLError';
import Loading from 'components/common/loading/Loading';
import { useTranslation } from 'react-i18next';
import { checkUpdatingAppointment } from 'components/app/bookingkit';

export type iAppointmentValidationSchema = Omit<AppointmentSchemaProps, 'formConfig'> & {
  formConfigs: FormConfigs;
};

const appointmentValidationSchema = ({ formConfigs, type }: iAppointmentValidationSchema) =>
  yup.lazy(({ targetGroupId }: { targetGroupId: string }) => {
    const formConfig = formConfigs[targetGroupId];

    return yup.object().shape({
      ...(type === AppointmentTypes.EVENT && customerSchema({ formConfig })),
      ...appointmentSchema({ formConfig, type }),
    });
  });

const CustomContainer: (
  props: AppointmentModalProps,
  refetchQueries?: InternalRefetchQueryDescriptor[],
) => CustomContainerProps<InitialValues> = (props, refetchQueries) =>
  function AppointmentContainer({ Component, Modal }) {
    const { t } = useTranslation();
    const { isEdit, appointmentId } = props;
    const {
      appointment,
      initialValues,
      isPushed,
      type,
      formConfigs,
      targetGroup,
      customerId,
      bookingRequestId,
      product,
      loading,
      error,
    } = useProduceData(props);
    const modalProps = useMemo(
      () => ({
        headerText: t('appointments.appointmentModal', { type: type ? t(`productType.${type}`) : '' }),
        width: screenSizeConfig.xl,
      }),
      [t, type],
    );
    if (error || loading || !type)
      return (
        <Modal {...modalProps}>
          {[
            error && <AlertGraphQLError error={error} />,
            loading && <Loading position="center" size={60} />,
            !type && !loading && <span>{t('errors.appointmentTypeNotFound')}</span>,
          ].filter(Boolean)}
        </Modal>
      );
    const fieldConfigs = targetGroup?.bookingFormConfig?.fieldConfig;
    return (
      <Component
        {...modalProps}
        validationSchema={() => appointmentValidationSchema({ formConfigs, type })}
        initialValues={initialValues}
        onSubmit={async (values) => {
          if (type === AppointmentTypes.EVENT) {
            const data = produceEventInput(values as InitialValues<true>, fieldConfigs);
            if (isEdit) {
              if (!appointmentId) {
                toast.error('Developer error: No appointment id provided for edit');
                return;
              }
              const result = await client.mutate<
                UpdateEventAppointmentMutation,
                UpdateEventAppointmentMutationVariables
              >({
                mutation: UpdateEventAppointmentDocument,
                variables: { id: appointmentId, data },
                refetchQueries,
              });
              checkUpdatingAppointment(appointment, result.data?.updateEventAppointment);
              toast.success(i18n.t('appointments.updateEventAppointmentSuccess'));
            } else {
              await client.mutate<CreateEventAppointmentMutation, CreateEventAppointmentMutationVariables>({
                mutation: CreateEventAppointmentDocument,
                variables: { data },
                refetchQueries,
              });
              toast.success(i18n.t('appointments.createEventAppointmentSuccess'));
            }
          } else if (type === AppointmentTypes.RECURRING_BOOKING_SLOT) {
            const { data } = await client.mutate<CreateRecurringEventMutation, CreateRecurringEventMutationVariables>({
              mutation: CreateRecurringEventDocument,
              variables: { data: produceRecurringEventInput(values) },
              refetchQueries,
            });
            toast.success(i18n.t('appointments.createRecurringEventSuccess', { count: data?.createRecurringEvent }));
          } else if (type === AppointmentTypes.BOOKING_SLOT) {
            const data = produceBookingSlotInput(values);
            if (isEdit) {
              if (!appointmentId) {
                toast.error('Developer error: No appointment id provided for edit');
                return;
              }
              const result = await client.mutate<
                UpdateBookingSlotAppointmentMutation,
                UpdateBookingSlotAppointmentMutationVariables
              >({
                mutation: UpdateBookingSlotAppointmentDocument,
                variables: { id: appointmentId, data },
                refetchQueries,
              });
              checkUpdatingAppointment(appointment, result.data?.updateBookingSlotAppointment);
              toast.success(i18n.t('appointments.updateAppointmentSuccess'));
            } else {
              await client.mutate<CreateBookingSlotAppointmentMutation, CreateBookingSlotAppointmentMutationVariables>({
                mutation: CreateBookingSlotAppointmentDocument,
                variables: { data },
                refetchQueries,
              });
              toast.success(i18n.t('appointments.createAppointmentSuccess'));
            }
          } else if (type === AppointmentTypes.PRIVATE_TOUR) {
            const data = producePrivateTourInput(
              {
                ...values,
                totalGuides: values.totalGuides === initialValues.totalGuides ? undefined : values.totalGuides,
              },
              fieldConfigs,
            );
            if (isEdit) {
              if (!appointmentId) {
                toast.error('Developer error: No appointment id provided for edit');
                return;
              }

              const result = await client.mutate<
                UpdateBookingRequestAppointmentMutation,
                UpdateBookingRequestAppointmentMutationVariables
              >({
                mutation: UpdateBookingRequestAppointmentDocument,
                variables: { data, appointmentId },
                refetchQueries,
              });
              checkUpdatingAppointment(appointment, result.data?.updateBookingRequestAppointment);
              toast.success(i18n.t('appointments.updateAppointmentSuccess'));
            } else {
              if (!bookingRequestId) {
                toast.error('Developer error: No booking request id provided for edit');
                return;
              }
              await client.mutate<
                AddAppointmentToBookingRequestMutation,
                AddAppointmentToBookingRequestMutationVariables
              >({
                mutation: AddAppointmentToBookingRequestDocument,
                variables: { data, id: bookingRequestId },
                refetchQueries,
              });
              toast.success(i18n.t('appointments.createAppointmentSuccess'));
            }
          }
        }}
        formikContent={() => (
          <AppointmentForm
            isPushed={isPushed}
            product={product}
            targetGroup={targetGroup}
            type={type}
            formConfigs={formConfigs}
            customerId={customerId}
            bookingRequestId={bookingRequestId}
            refetchQueries={refetchQueries}
          />
        )}
        customFooter={({ onClose, disabled }) => (
          <>
            <Button onClick={onClose} variant="outline-primary">
              {i18n.t('cancel')}
            </Button>
            <Button endIcon={<Save />} disabled={disabled} type="submit">
              {i18n.t('save')}
            </Button>
          </>
        )}
      />
    );
  };

const appointmentModal = async (props: AppointmentModalProps, refetchQueries?: InternalRefetchQueryDescriptor[]) => {
  InputModalConfirm({
    CustomContainer: CustomContainer(props, refetchQueries),
  });
};
export default appointmentModal;
