import { checkUpdatingBookingRequest } from 'components/app/bookingkit';
import ConfirmModal from 'components/common/Modal/ConfirmModal';
import { DEFAULT_APPOINTMENT_DURATION_HOURS } from 'constants/appointment';
import { add } from 'date-fns';
import client from 'graphql/apollo';
import { AppointmentFragment } from 'graphql/fragments/appointments/generated/appointmentFragment';
import {
  UpdatePrivateTourAppointmentsStartDateDocument,
  UpdatePrivateTourAppointmentsStartDateMutation,
  UpdatePrivateTourAppointmentsStartDateMutationVariables,
} from 'graphql/mutations/appointment/generated/UpdatePrivateTourAppointmentsStartDate';
import { Scalars } from 'graphql/types.generated';
import i18n from 'i18n';
import { toast } from 'react-toastify';

const applyDateModal = (bookingRequestId: string, date: Scalars['DateTime'], appointments: AppointmentFragment[]) => {
  ConfirmModal({
    headerText: i18n.t('bookingRequestView.applyDateConfirmation.title'),
    description: i18n.t('bookingRequestView.applyDateConfirmation.description'),
    onOk: async () => {
      const result = await client.mutate<
        UpdatePrivateTourAppointmentsStartDateMutation,
        UpdatePrivateTourAppointmentsStartDateMutationVariables
      >({
        mutation: UpdatePrivateTourAppointmentsStartDateDocument,
        variables: {
          bookingRequestId,
          data: { startDate: date, endDate: add(new Date(date), { hours: DEFAULT_APPOINTMENT_DURATION_HOURS }) },
        },
      });
      checkUpdatingBookingRequest(appointments, result.data?.updateBookingRequestAppointments);
      toast.success(i18n.t('bookingRequestView.applyDateConfirmation.success'));
    },
  });
};

export default applyDateModal;
