import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { BookingkitConnectionForAppointmentFragmentDoc } from '../../../fragments/bookingkit/generated/BookingkitConnectionForAppointmentFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PushAppointmentToBookingkitMutationVariables = SchemaTypes.Exact<{
  appointmentId: SchemaTypes.Scalars['String'];
}>;

export type PushAppointmentToBookingkitMutation = {
  __typename?: 'Mutation';
  pushAppointmentToBookingkit: {
    __typename?: 'Appointment';
    id: string;
    bookingkitConnection?: {
      __typename?: 'AppointmentBookingkitConnection';
      dateId?: string | null;
      orderCode?: string | null;
      dateSyncedAt?: string | Date | null;
      dateSyncSuccess?: boolean | null;
      orderSuccess?: boolean | null;
      orderSyncedAt?: string | Date | null;
      errorName?: string | null;
      errorMessage?: string | null;
      ignoreError?: boolean | null;
    } | null;
  };
};

export const PushAppointmentToBookingkitDocument = gql`
  mutation PushAppointmentToBookingkit($appointmentId: String!) {
    pushAppointmentToBookingkit(appointmentId: $appointmentId) {
      id
      bookingkitConnection {
        ...BookingkitConnectionForAppointment
      }
    }
  }
  ${BookingkitConnectionForAppointmentFragmentDoc}
`;
export type PushAppointmentToBookingkitMutationFn = Apollo.MutationFunction<
  PushAppointmentToBookingkitMutation,
  PushAppointmentToBookingkitMutationVariables
>;

/**
 * __usePushAppointmentToBookingkitMutation__
 *
 * To run a mutation, you first call `usePushAppointmentToBookingkitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePushAppointmentToBookingkitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pushAppointmentToBookingkitMutation, { data, loading, error }] = usePushAppointmentToBookingkitMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function usePushAppointmentToBookingkitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PushAppointmentToBookingkitMutation,
    PushAppointmentToBookingkitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PushAppointmentToBookingkitMutation, PushAppointmentToBookingkitMutationVariables>(
    PushAppointmentToBookingkitDocument,
    options,
  );
}
export type PushAppointmentToBookingkitMutationHookResult = ReturnType<typeof usePushAppointmentToBookingkitMutation>;
export type PushAppointmentToBookingkitMutationResult = Apollo.MutationResult<PushAppointmentToBookingkitMutation>;
export type PushAppointmentToBookingkitMutationOptions = Apollo.BaseMutationOptions<
  PushAppointmentToBookingkitMutation,
  PushAppointmentToBookingkitMutationVariables
>;
