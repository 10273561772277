import { BackendErrorTranslations, Translations } from './en';

const translations: Translations = {
  translation: {
    errors: {
      appointmentTypeNotFound: 'Termin nicht gefunden',
      bookingRequestNotFound: 'Anfrage nicht gefunden',
      targetGroupsTypeNotFound: 'Zielgruppe nicht gefunden',
      customerNotFound: 'Kunde nicht gefunden',
      serverConnectionError: 'Verbindung zum Server - nicht möglich.',
    },
    disabledDay: 'Dieser Tag ist nicht verfügbar',
    closeConfirmation:
      'Wenn Sie jetzt dieses Fenster schliessen, gehen nicht abgespeicherte Änderungen verloren. Möchten Sie dennoch das Fenster schliessen?',
    notApplicable: 'N/A',
    yes: 'Ja',
    no: 'Nein',
    continue: 'Weiter',
    cancel: 'Löschen',
    ok: 'Ok',
    edit: 'Ändern',
    delete: 'Löschen',
    create: 'Erstellen',
    save: 'Speichern',
    apply: 'Anwenden',
    reset: 'Reset',
    empty: 'There is no data',
    close: 'Schliessen',
    send: 'Senden',
    role: {
      ADMIN: 'Admin',
      GUIDE: 'Guide',
    },
    appointmentType: {
      BOOKING_SLOT: 'Zubucher',
      EVENT: 'Event',
      PRIVATE_TOUR: 'Private Tour',
    },
    bookingRequestSort: {
      NEWEST_REQUEST: 'Neueste Anfrage',
      UPCOMING_TOUR: 'Anstehende Touren',
    },
    productType: {
      RECURRING_BOOKING_SLOT: 'Serientermin',
      BOOKING_SLOT: 'Zubucher',
      EVENT: 'Event',
      PRIVATE_TOUR: 'Private Tour',
    },
    infoForOffice: {
      INVOICE_NOT_SENT: 'Rechnung ausständig',
      PARTIAL_INVOICE: 'Teilrechnung',
      TICKET_NOT_SENT: 'Unterlagen ausständig',
    },
    fontSize: {
      small: 'Klein',
      normal: 'Mittel',
      large: 'Groß',
      huge: 'Sehr Groß',
    },
    salutations: {
      mrs: 'Frau',
      mr: ' Herr',
    },
    locale: {
      en: 'Englisch',
      de: 'Deutsch',
    },
    bookingRequestStatus: {
      ACCEPTED: 'Bestätigt',
      CANCELED: 'Storniert',
      EMAIL_SENT: 'Angefragt',
      NEW: 'Neu',
    },
    appointmentStatus: {
      NEW: 'Neu',
      REQUESTED: 'Angefragt',
      SCHEDULED: 'Bestätigt',
      AWAITS_FEEDBACK: 'Fehlende Rückmeldung Guide',
      COMPLETED: 'Erledigt',
      CANCELED: 'Storniert',
    },
    pageTitles: {
      appointments: 'Termine',
      bookingRequests: 'Anfragen',
      emailTemplates: 'Email Vorlagen',
      userManagement: 'Nutzerverwaltung',
      systemConfig: 'Einstellungen',
      logout: 'Logout',
      myProfile: 'Mein Profil',
    },
    RichEditor: {
      link: {
        modalTitle: 'Link hinzufügen',
        fields: {
          name: {
            label: 'Name',
          },
          value: {
            label: 'Wert',
            invalid: 'unvollständige URL',
            description:
              '<div><div>Example:</div><div>https://google.com - insert a web site link</div><div>mailto:someone@gmail.com - insert a create email link</div><div>tel:+49123456789 - insert a mobile number</div></div>',
          },
        },
      },
      image: {
        modalTitle: 'Bild hinzufügen',
        fields: {
          value: {
            label: 'URL',
            invalid: 'Unvollständige/falsche URL',
            placeholder: 'https://....',
          },
        },
      },
    },
    appointments: {
      view: 'Ansicht',
      tour: 'Tour',
      createRecurringEventSuccess_one: '{{count}} Serientermin wurde erstellt',
      createRecurringEventSuccess_other: '{{count}} Serientermin wurde erstellt',
      createEventAppointmentSuccess: 'Termin wurde erstellt',
      updateEventAppointmentSuccess: 'Termin wurde erstellt',
      createAppointmentSuccess: 'Termin wurde erstellt',
      createBookingRequestSuccess: 'Anfrage wurde erstellt',
      provideGuideFeedbackSuccess: 'Rückmeldung vom Guide vorhanden',
      deleteAppointmentSuccess: 'Termin wurde gelöscht',
      updateAppointmentSuccess: 'Termin wurde überarbeitet',
      updateAppointmentInfoForOfficeSuccess: 'Info für Büro des Termins wurde überarbeitet',
      updateAppointmentGuideSuccess: 'Guide des Termins wurde überarbeitet',
      cancelApppointmentSuccess: 'Termin abgesagt',
      bookingRequestAlert: 'Neue Anfrage erhalten <a>booking form</a>.',
      resetFilters: 'Filter rücksetzen',
      privateTourAlert:
        'Dieser Termin ist Teil einer Anfrage für mehrere Gruppen. Kundeninformation <a>click hier</a> ändern. Änderungen an der Kundeninformation wird für alle Gruppen übernommen.',
      requestsTab: 'Anfrageformulare',
      appointmentsTab: 'Termine',
      appointmentModal: 'Termine: $t(productType.{{type}})',
      createBookingRequestModal: 'Neue Anfrage erstellen',
      appointmentInfoForOfficeModal: 'Info für Büro des Termin ändern',
      appointmentGuideModal: 'Guide ändern',
      provideGuideFeedbackModal: 'Rückmeldung Guide eingeben',
      guides_one: 'guide',
      guides_other: 'guides',
      deleteConfirmation: {
        title: 'Termin löschen',
        description: 'Termin wirklich löschen?',
      },
      cancelConfirmation: {
        title: 'Termin absagen',
        description: 'Möchten Sie den Termin stornieren?',
      },
      columns: {
        date: 'Datum',
        time: 'Zeit',
        code: 'Code',
        type: 'Type',
        organization: 'Auftraggeber',
        contact: 'Kontakt',
        totalParticipants: 'TN',
        totalAccompanyingPersons: 'Begleiter',
        status: 'Status',
        infoForOffice: 'Info für Büro',
        language: 'Sprache',
        guides: 'Guides',
        service: 'Service',
        guide: 'Guide',
        product: 'Produkt',
        guideFeedback: 'Guide feedback',
        meetingPlace: 'Treffpunkt',
        lastEmailDate: 'Angebotsdatum',
        infoForGuide: 'Infos für Guides',
        bookingkit: 'Bookingkit',
      },
      bookingkitCell: {
        syncNotEnabledForProductTooltip: 'Bookingkit sync ist für diesen Termin nicht möglich {{name}}',
        syncNotEnabledForProductBadge: 'Nicht möglich',
        openDateInBookingkitLink: 'Öffnen',
        pushedSuccessfullyNotification: 'Erfolgreiche Übertragung zu Bookingkit',
        syncScheduled: 'Sync ist geplant. Laden Sie die Seite neu',
        manualPushButton: 'Übertragen',
        ignoreErrorButton: 'Ignorieren',
        retrySyncButton: 'Nochmals versuchen',
        status: {
          PUSHED: 'Übertragen',
          NOT_PUSHED: 'Nicht übertragen',
          ERROR: 'Fehler',
          UNKNOWN: 'Unbekannt',
          IGNORED: 'Ignoriert',
        },
      },
      bookingkitDateCancelConfirmation: {
        title: 'Termin in Bookingkit löschen',
        description: 'DUm diesen Termin zu löschen, muss er ebenso in Bookingkit manuell gelöscht werden',
        openBookingkitLink: 'In Bookingkit öffnen',
        okText: 'Erledigt',
      },
      fields: {
        totalGuides: {
          label: 'Anzahl der Guides',
        },
        guideFeedback: {
          label: 'Feedback:',
          checkIn: 'Check-in:',
        },
        comment: {
          label: 'Comment',
        },
        timeRange: {
          label: 'Zeit',
          endTime: 'Ende',
          startTime: 'Anfang',
        },
        dateRange: {
          label: 'Period',
        },
        date: {
          label: 'Datum',
          placeholder: 'Datum auswählen',
          description: '',
          endDate: 'Datum Ende',
          startDate: 'Datum Anfang',
        },
        infoForOffice: {
          label: 'Info für Büro',
          placeholder: 'Info für Büro auswählen',
        },
        search: {
          placeholder: 'Suchen...',
        },
        language: {
          label: 'Tour Sprache',
        },
        code: {
          label: 'Code',
          description: 'Der Code wird automatisch ausgefüllt: Ausgewähltes Tourenthema und Zielgruppe',
        },
        status: {
          label: 'Status',
        },
        guideId: {
          label: 'Guide',
          description: 'Kann leer sein – Kann später hinzugefügt werden',
        },
        tourTopicId: {
          label: 'Tour topic',
          description: 'Das Tourenthema wird automatisch ausgefüllt: Ausgewähltes Produkt',
        },
        productId: {
          label: 'Produkt',
          description: 'Diese Liste enhält alle "Private Touren"',
        },
        appointmentLanguage: {
          label: 'Sprache',
          description: 'Sprache wird automatisch ausgefüllt: Ausgewähltes Produkt',
        },
        targetGroupId: {
          label: 'Zielgruppe',
          description: '',
        },
        meetingPlace: {
          label: 'Treffpunkt',
          description: 'Treffpunkt wird automatisch ausgefüllt: Ausgewähltes Produkt',
        },
        weekDays: {
          label: 'Wochentage',
        },
      },
    },
    bookingUpdateManuallyConfirmation: {
      description:
        'Bitte schicken Sie die aktualisierte Buchungsbestätigung an den Kunden (manueller Eingriff erforderlich):',
      order: 'Bestellung',
      eventDate: 'Termoin',
    },
    bookingRequestView: {
      confirm: 'Anfrage bestätigen',
      reopen: 'Wiederherstellen',
      cancel: 'Anfrage stornieren',
      updateBookingRequestSuccess: 'Anfrageforumlar wurde überarbeitet',
      updateCustomerSuccess: 'Kunde wurde überarbeitet',
      appointments: 'Termine',
      title: 'Anfragen',
      request: 'Anfrage',
      customer: 'Kunde',
      sentEmails: 'Gesendete Emails',
      newEmail: 'Angebot schicken',
      viewFullBody: 'Vollansicht',
      applyDateConfirmation: {
        title: 'Datum anwenden',
        description: 'Dieses Datum anwenden?',
        success: 'Neues Datum angewendet',
      },
      updateCustomerModal: {
        title: 'Kundeninformation ändern',
        organization: 'Organisation',
        contactPerson: 'Kontaktperson',
      },
      newEmailModalTitle: 'Email senden',
      newEmailModal: {
        sendTestEmailButton: 'Test email',
        emailSent: 'Email gesendet',
      },
      updateBookingRequestModal: 'Anfrage ändern',
      columns: {
        date: 'Datum',
        time: 'Zeit',
        code: 'Code',
        organization: 'Organisation',
        contact: 'Kontaktperson',
        totalParticipants: 'TN',
        totalAccompanyingPersons: 'Begleitpersonen',
        status: 'Status',
        language: 'Sprache',
        guides: 'Guides',
        emails: 'Emails',
        service: 'Service',
        bookingKit: 'Bookingkit',
        meetingPlace: 'Treffpunkt',
        product: 'Produkt',
        template: 'Vorlage',
        subject: 'Betreff',
        body: 'Nachricht',
        delivery: 'Delivery',
      },
      emailMessage: {
        deliveryDetails: {
          status: 'Status',
          statusDelivered: 'Delivered',
          statusNotDelivered: 'Not delivered',
          statusUnknown: 'Unknown',
          serverResponse: 'Server response',
          events: 'Events',
        },
      },
      fields: {
        skipAutomaticEmail: {
          label: 'Automatisches Email nicht senden',
        },
        targetGroupId: {
          label: 'Art der Organisation',
        },
        to: {
          label: 'Zu',
        },
        organizationName: {
          label: 'Name: Organisation',
        },
        tourTopicId: {
          label: 'Tourenthema',
        },
        name: {
          label: 'Name',
        },
        email: {
          label: 'Email Adresse',
        },
        phoneNumber: {
          label: 'Handynummer',
        },
        street: {
          label: 'Straße',
        },
        buildingNumber: {
          label: 'Nummer',
        },
        zip: {
          label: 'PLZ',
        },
        city: {
          label: 'Stadt',
        },
        uidNumber: {
          label: 'UID/ZVR Nummer ',
        },
        internalId: {
          label: 'Interne ID',
        },
        code: {
          label: 'Code',
        },
        createdAt: {
          label: 'Erstellt am',
        },
        dateTime: {
          label: 'Datum und Uhrzeit',
        },
        language: {
          label: 'Sprache der Tour',
        },
        altDateTime: {
          label: 'Alternatives Datum und Zeit',
        },
        howDidYouHear: {
          label: 'Wie haben Sie von uns gehört?',
        },
        totalParticipants: {
          label: 'Anzahl der Teilnehmer_innen',
        },
        status: {
          label: 'Status',
        },
        totalAccompanyingPersons: {
          label: 'Anzahl der Begleitpersonen',
        },
        automationDetails: {
          label: 'Details zur Automatisierung',
          refreshAutomationDetailsSuccess: 'Details zur Automatisierung wurden überarbeitet',
        },
        ageOfParticipants: {
          label: 'Alter der Teilnehmer',
        },
      },
    },
    emailTemplates: {
      isTargetGroupTemplate: 'Tour',
      createEmailTemplateSuccess: 'Email-Vorlage wurde erstellt',
      deleteConfirmation: {
        title: 'Diese Email-Vorlage löschen',
        description: 'Sind Sie sicher, dass Sie diese Email-Vorlage löschen möchten?',
        success: 'Email-Vorlage wurde gelöscht',
      },
      title: 'Email-Vorlage',
      edit: {
        modalTitle: 'Email-Vorlage ändern',
        button: 'Ändern',
        successfullyUpdated: 'Email-Vorlage wrude geändert.',
      },
      fields: {
        language: {
          label: 'Sprache',
        },
        name: {
          label: 'Name',
        },
        subject: {
          label: 'Betreff',
        },
        body: {
          label: 'Nachricht',
        },
        emailTemplateId: {
          label: 'Vorlage',
        },
      },
    },
    userManagement: {
      duplicatedErrorMessage: 'Nutzer mit dieser Email existiert bereits',
      createUserSuccess: 'Neuer Nutzer erstellt',
      updateUserSuccess: 'Neuer Nutzer überarbeitet',
      deleteConfirmation: {
        title: 'Nutzer löschen',
        description: 'Möchten Sie diesen Nutzer löschen?',
        success: 'Nutzer gelöscht',
      },
      create: 'Nutzer erstellen',
      update: 'Nutzer überarbeiten',
      title: 'Nutzerverwaltung',
      columns: {
        actions: 'Actions',
        active: 'Aktiv',
        email: 'Email',
        fullName: 'Vor- und Nachname',
        lastActivityDate: 'Letzer login',
        phoneNumber: 'Handynummer',
      },
      fields: {
        fullName: {
          label: 'Vor- und Nachname',
          placeholder: 'Geben Sie hier den Vor- und Nachnamen ein',
        },
        phoneNumber: {
          label: 'Handynummer',
        },
        email: {
          label: 'Email Adresse',
          placeholder: 'Geben Sie hier die Email-Adresse ein',
        },
        password: {
          label: 'Passwort',
          placeholder: 'Passwort',
        },
        role: {
          label: 'Berechtigungen',
          placeholder: 'Auswahl der Berechtigungen',
        },
      },
    },
    userProfile: {
      changeLocaleSelectLabel: 'User interface locale',
      changePassword: {
        success: 'Passwort wurde geändert',
        button: 'Passwort ändern',
        modalTitle: 'Passwort ändern',
      },
      emailNotificationsToggle: 'Email Benachrichtigungen',
      registered: 'Registriert:',
      title: 'Nutzerprofil',
      fields: {
        password: {
          label: 'Passwort',
        },
        newPassword: {
          label: 'Neues Passwort',
        },
        repeatedNewPassword: {
          label: 'Neues Passwort wiederholen',
          noMatch: 'Passwörter stimmen nicht überein',
        },
      },
    },
    Placeholder: {
      title: 'Platzhalter',
    },
    systemConfig: {
      bookingkit: 'Bookingkit',
      all: 'ALLE',
      createProductModal: 'Produkt erstellen',
      updateProductModal: 'Produkt ändern',
      createProductSuccess: 'Produkt wurde erstellt',
      updateProductSuccess: 'Produkt wurde aktualisiert',
      deleteProductConfirmation: {
        title: 'Produkt löschen',
        description: 'Möchten Sie diesen Produkt löschen?',
        success: 'Produkt gelöscht',
      },
      deleteBlockedDateConfirmation: {
        title: 'Datum löschen',
        description: 'Möchten Sie das geblockte Datum löschen?',
        success: 'Geblocktes Datum gelöscht.',
      },
      createBlockedDateSuccess: 'Geblocktes Datum erstellen',
      updateBlockedDateSuccess: 'Geblocktes Datum aktualisieren',
      updateAutomationConfigSuccess: 'Automatisierungseinstellungen wurden aktualisiert',
      resetAutomationConfigSuccess: 'Automatisierungseinstellungen wurden zurückgesetzt',
      products: 'BookingKit Produkte',
      organizationTypes: 'Art: Organisation',
      tourTopics: 'Tourenthema',
      meetingPlaces: 'Optionen: Treffpunkt',
      services: 'Optionen: Service',
      dateTime: 'Konfiguration von Datum und Uhrzeit',
      title: 'Systemeinstellungen',
      automation: 'Automatisierung',
      bookingRequest: 'Anfrageformular',
      tours: 'Touren',
      blockDates: 'Datum blockiert',
      startTime: 'Konfiguration der Startzeiten',
      save: 'Änderungen speichern',
      revert: 'Änderungen rückgängig machen',
      columns: {
        integrations: 'Integrations',
        type: 'Produkt',
        place: 'Ort',
        language: 'Sprache',
        tourTopic: 'Tourenthema',
        abbreviation: 'Code',
        name: 'Name',
        startPointAddress: 'Treffpunkt',
        endPointAddress: 'Ende',
        startDate: 'Datum: Anfang',
        endDate: 'Datum: Ende',
        disabledCodes: 'Disabled codes',
        blockedBy: 'Blocked by',
      },
      fields: {
        bookingkitEventId: {
          label: 'Bookingkit Event',
        },
        bookingkitPriceId: {
          label: 'Price',
        },
        bookingkitSyncEnabled: {
          label: 'Sync with Bookingkit',
        },
        name: {
          label: 'Name',
        },
        type: {
          label: 'Type',
        },
        meetingPointName: {
          label: 'Place',
        },
        startPointAddress: {
          label: 'Start point address',
        },
        endPointAddress: {
          label: 'End point address',
        },
        maxParticipants: {
          label: 'MaxTeilnehmer',
          description: 'Maximale Anzahl an Teilnehmer für automatisierte Annahme',
        },
        participantsManualReviewRangeStart: {
          label: 'TeilnehmeranzahlHändischÜberarbeiten von',
          description: 'Anzahl an Teilnehmer wenn die Anfrage händisch überarbeitet werden soll',
        },
        participantsManualReviewRangeEnd: {
          label: 'TeilnehmeranzahlHändischÜberarbeiten bis',
        },
        isMaxToursDuringDayByTourTopic: {
          label: 'MaxAnzahlanTourenproTag zum Tourenthema',
        },
        maxToursDuringDay: {
          label: 'MaxAnzahlanTourenproTag',
          tourTopicLabel: '"{{name}}" MaxAnzahlanTourenproTag',
          description: 'max. Anzahl an Touren, die pro Datum automatisch angeboten werden dürfen',
        },
        maxParticipantsInGroup: {
          label: 'MaxTeilnehmeranzahlProGuide',
          description: 'Max. Anzahl an Teilnehmer_innen pro Guide',
        },
        disabledSubmission: {
          label: 'Anfrageformular für neue Anfragen sperren',
          description: 'Auswählen, wenn neue Kundenanfragen momentan unerwünscht sind',
        },
        disabledSubmissionMessage: {
          label: 'Keine Anfrageformular-Nachricht',
        },
        blockedDateRange: {
          label: 'Block dates between',
          startPlaceholder: 'Start date',
          endPlaceholder: 'End date',
          startName: 'Start date',
          endName: 'End date',
        },
        disabledCodes: {
          label: 'Disabled codes',
          placeholder: 'Select disabled codes',
        },
        isAllCodes: {
          label: 'Block all codes',
        },
      },
    },
    bookingRequest: {
      goBackConfirmation:
        'Wenn Sie jetzt zurück gehen, werden die ausgefüllten Informationen verloren gehen. Dennoch zurück gehen?',
      header: 'Anfrageformular (Seite {{currentIndex}}/{{stepNumber}})',
      offerTitle: '',
      organizationTitle: 'Adresse / Rechnungsanschrift',
      contactTitle: 'Kontaktperson',
      completed: {
        title: 'Danke!',
        description: 'Wir melden uns so schnell wie möglich mit einem passenden Angebot!',
        goBack: 'Zur Website',
      },
      pricingFormAlert:
        'Achtung: in den letzten zwei Schulwochen vor den Sommerferien sind unsere Startzeiten: 08h00 - 11h00 - 14h00 und 17h00, bitte wählen Sie für Ihre Exkursionen in diesem Zeitraum genau einen dieser Zeitslots aus',
      fields: {
        targetGroupId: {
          label: 'Ich organisiere eine Exkursion für',
          name: 'Anfrageformular: Zielgruppe',
          placeholder: 'Bitte wählen Sie',
        },
        // pricesCheck: {
        //   label: 'Ich bin mit den Informationen und Preisen einverstanden',
        //   required: 'Bitte bestätigen Sie, dass Sie mit den Informationen einverstanden sind',
        // },
        infoForGuides: {
          label: 'Informationen für den Guide',
          description: 'Informationen für den Guide, KEINE Fragen.',
        },
        privacyPoliceCheck: {
          label: 'Ich habe die <a>Datenschutzerklärung</a> gelesen und stimme ihr zu',
          required: 'Bitte bestätigen Sie, dass Sie mit den Informationen einverstanden sind',
        },
        tourTopicId: {
          label: 'Ich hätte gerne ein Exkursion mit dem Tourenthema',
          name: 'Tourenthema',
        },
        language: {
          label: 'Sprache der Tour',
        },
        date: {
          label: 'Datum',
          placeholder: 'Wählen Sie Ihr Wunschdatum',
        },
        time: {
          label: 'Zeit',
          placeholder: 'Startzeit',
        },
        altDate: {
          label: 'Alternatives Datum',
          placeholder: 'Wählen Sie ein alternatives Datum',
        },
        altTime: {
          label: 'Alternative Zeit',
          placeholder: 'Startzeit für alternatives Datum',
        },
        totalSlots: {
          label: 'Anzahl an Buchungszeilen',
        },
        totalAccompanyingPersons: {
          label: 'Anzahl an Begleitpersonen',
        },
        totalParticipants: {
          label: 'Anzahl an Teilnehmer_innen',
        },
        salutation: {
          label: 'Anrede',
          placeholder: 'Bitte wählen Sie',
        },
        firstName: {
          label: 'Vorname',
        },
        lastName: {
          label: 'Nachname',
        },
        email: {
          label: 'Email Adresse',
        },
        phoneNumber: {
          label: 'Handynummer',
          invalid: 'Mobile number is not valid',
        },
        name: {
          label: 'Name der Organisation',
        },
        address: {
          label: 'Staße',
        },
        buildingNumber: {
          label: 'Straßennummer',
        },
        zip: {
          label: 'PLZ',
        },
        city: {
          label: 'Stadt',
        },
        uidNumber: {
          label: 'UID/ATU oder ZVR Nummer',
        },
        howDidYouHear: {
          label: 'Wie haben Sie von SHADES TOURS gehört?',
        },
        ageOfParticipants: {
          label: 'Alter der Teilnehmer',
          addon: 'Jahre',
        },
      },
    },
    login: {
      title: 'Anmelden',
      subtitle: 'Willkommen zurück! Login-Details eingeben.',
      fields: {
        email: {
          label: 'Email',
          placeholder: 'Email-Adresse eingeben',
        },
        password: {
          label: 'Passwort',
          placeholder: 'Passwort',
        },
        rememberMe: {
          label: 'Merken',
        },
      },
    },
  },
};

export const backendErrors: BackendErrorTranslations = {
  // appointment
  APPOINTMENT_NOT_FOUND: 'Termin nicht gefunden',
  APPOINTMENT_INCORRECT_TYPE: 'Appointment type is incorrect',
  APPOINTMENT_WITHOUT_CUSTOMER: 'Termin ohne Kunde',
  // automation config
  AUTOMATION_CONFIG_NOT_FOUND: 'Automatisierung nicht gefunden',
  // booking request
  BOOKING_REQUEST_NOT_FOUND: 'Anfrage nicht gefunden',
  BAD_BOOKING_REQUEST: 'Can you check the booking request form again? There are inconsistencies',
  // customer
  CUSTOMER_NOT_FOUND: 'Kunde nicht gefunden',
  CUSTOMER_NOT_FOUND_IN_INPUT: 'Kunde muss eingegeben werden',
  // email template
  EMAIL_TEMPLATE_NOT_FOUND: 'Email-Vorlage nicht gefunden',
  EMAIL_TEMPLATE_FOR_TOUR_TOPIC_CANT_BE_REMOVED:
    'Diese Email-Vorlage kann nicht gelöscht werden weil sie in den Systemeinstellungen genutzt wird',
  // guide feedback
  GUIDE_FEEDBACK_NOT_FOUND: 'Guide feedback nicht gefunden',
  // product
  PRODUCT_NOT_FOUND: 'Produkt nicht gefunden',
  PRODUCT_FOR_TOUR_TOPIC_CANT_BE_REMOVED: 'You cannot remove the product because it is used in system config',
  BAD_PRODUCT_INPUT: 'Können Sie das Produktformular noch einmal überprüfen? Es gibt Ungereimtheiten',
  PROHIBIT_CHANGE_PUSHED_APPOINTMENT_PRODUCT:
    'You cannot update the product field because the appointment is pushed to the Bookingkit',
  // target group
  TARGET_GROUP_NOT_FOUND: 'Zielgruppe nicht gefunden',
  // tour topic
  TOUR_TOPIC_NOT_FOUND: 'Tourenthema nicht gefunden',
  TOUR_TOPIC_WITHOUT_PRODUCT: 'Tourenthema ohne Produkt',
  // user
  USER_NOT_FOUND: 'Nutzer nicht gefunden',
  USER_DEACTIVATE_HIMSELF: 'Sie können sich nicht selbst deaktivieren',
  USER_DELETE_HIMSELF: 'Sie können sich nicht selbst löschen',
  USER_EDIT_HIMSELF: 'Sie haben Werte geändert, die Sie nicht ändern dürfen',
  // auth
  INCORRECT_PASSWORD: 'Falsches Passwort',
  INCORRECT_CREDENTIALS: 'Falsches Benutzername oder Passwort',
  MISSING_PASSWORD_ERROR: 'Kein Passwort wurde eingegeben',
  ATTEMPT_TOO_SOON_ERROR: 'Dieses Nutzerkonto ist momentan gesperrt. Versuchen Sie es später.',
  TOO_MANY_ATTEMPTS_ERROR: 'Dieses Nutzerkonto ist vorrübergehen gesperrt, da zu viele Fehleingaben eingegeben wurden.',
  NO_SALT_VALUE_STORED_ERROR: 'Fehlgeschlagene Authentifizierung. No salt value stored',
  MISSING_USERNAME_ERROR: 'Kein Benutzername oder Email wurde eingegeben',
  USER_EXISTS_ERROR: 'Dieser Benutzername existiert bereits. Wählen Sie einen anderen.',
  TOKEN_EXPIRED: 'Token abgelaufen',
};

export default translations;
