import React, { useMemo } from 'react';
import { DatePickerFormik } from 'components/inputs/DatePicker';
import NumberInput from 'components/inputs/NumberInput';
import TextInput from 'components/inputs/TextInput';
import { Alert, Col, ColProps, InputGroup, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { CalendarCheck } from 'react-bootstrap-icons';
import Offer from './Offer';
import * as yup from 'yup';
import i18n from 'i18n';
import FastField from 'components/common/FastField';
import { useBookingContext } from 'pages/BookingRequestPage/context';
import { ValidationSchemaParams } from 'pages/BookingRequestPage/components/StepLayout';
import Checkbox from 'components/inputs/Checkbox';
import { FormConfig } from '../utils';
import { TIME_FORMAT } from 'constants/date';
import { Trans, useTranslation } from 'react-i18next';
import { TimePickerFormik } from 'components/inputs/TimePicker';
import { localMaxTime, localMinTime } from 'constants/bookingRequest';
import config from 'config/config';
import { iRequired } from 'components/common/Required';
import { PARTICIPANTS_MAX_LIMIT } from 'constants/appointment';
import { useBlockedDatesQuery } from 'graphql/queries/blockedDate/generated/BlockedDates';
import AlertGraphQLError from 'components/common/AlertGraphQLError';
import { iDatePickerProps } from 'components/inputs/DatePicker/DatePickerFormik';
import { Field } from 'formik';
import { OverlayInjectedProps } from 'react-bootstrap/Overlay';
import { eachDayOfInterval, startOfDay } from 'date-fns';
import { uniq } from 'lodash';

export const totalParticipantsSchema = (min = 1) => ({
  totalParticipants: yup
    .number()
    .required()
    .nullable()
    .min(min)
    .max(PARTICIPANTS_MAX_LIMIT)
    .label(i18n.t('bookingRequest.fields.totalParticipants.label')),
});

type TotalParticipantsSchemaProps = { minTotalParticipants?: number };

export const schema = ({ formConfig, minTotalParticipants }: ValidationSchemaParams & TotalParticipantsSchemaProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fields: Record<string, any> = {};
  if (formConfig?.ageOfParticipants.visible) {
    fields.ageOfParticipants = yup.string().max(1024).label(i18n.t('bookingRequest.fields.ageOfParticipants.label'));
    if (formConfig?.ageOfParticipants.required) fields.ageOfParticipants = fields.ageOfParticipants.required();
  }
  if (formConfig?.totalAccompanyingPersons.visible) {
    fields.totalAccompanyingPersons = yup
      .number()
      .nullable()
      .min(1)
      .max(10)
      .label(i18n.t('bookingRequest.fields.totalAccompanyingPersons.label'));
    if (formConfig?.totalAccompanyingPersons.required)
      fields.totalAccompanyingPersons = fields.totalAccompanyingPersons.required();
  }

  return {
    date: yup.string().nullable().required().label(i18n.t('bookingRequest.fields.date.label')),
    time: yup.string().nullable().required().label(i18n.t('bookingRequest.fields.time.label')),
    altDate: yup.string().nullable().required().label(i18n.t('bookingRequest.fields.altDate.label')),
    altTime: yup.string().nullable().required().label(i18n.t('bookingRequest.fields.altTime.label')),
    // pricesCheck: yup.boolean().nullable().oneOf([true], i18n.t('bookingRequest.fields.pricesCheck.required')),
    privacyPoliceCheck: yup
      .boolean()
      .nullable()
      .oneOf([true], i18n.t('bookingRequest.fields.privacyPoliceCheck.required')),
    ...totalParticipantsSchema(minTotalParticipants),
    ...fields,
  };
};

export const validationSchema = ({
  formConfig,
  minTotalParticipants,
}: ValidationSchemaParams & TotalParticipantsSchemaProps) => {
  return yup.object().shape({
    ...schema({ formConfig, minTotalParticipants }),
  });
};

export const TotalParticipantsInput = () => (
  <FastField
    isRequired
    name="totalParticipants"
    component={NumberInput}
    min={0}
    max={PARTICIPANTS_MAX_LIMIT}
    isInteger
    label="bookingRequest.fields.totalParticipants.label"
    placeholder="bookingRequest.fields.totalParticipants.placeholder"
  />
);

export const TotalAccompanyingPersonsInput = ({ min = 1, isRequired }: iRequired & { min?: number }) => (
  <FastField
    isRequired={isRequired}
    name="totalAccompanyingPersons"
    component={NumberInput}
    min={min}
    max={10}
    isInteger
    label="bookingRequest.fields.totalAccompanyingPersons.label"
    placeholder="bookingRequest.fields.totalAccompanyingPersons.placeholder"
  />
);

export const TotalAccompanyingPersonsFormConfigInput = ({
  formConfig,
  colProps,
}: {
  formConfig?: FormConfig;
  colProps?: ColProps;
}) =>
  formConfig?.totalAccompanyingPersons.visible ? (
    <Col {...colProps}>
      <TotalAccompanyingPersonsInput isRequired={formConfig?.totalAccompanyingPersons.required} />
    </Col>
  ) : null;

export const AgeOfParticipantsInput = ({
  formConfig,
  label = 'bookingRequest.fields.ageOfParticipants.label',
  colProps,
}: {
  formConfig?: FormConfig;
  label?: string;
  colProps?: ColProps;
}) => {
  const { t } = useTranslation();
  return formConfig?.ageOfParticipants?.visible ? (
    <Col {...colProps}>
      <FastField
        isRequired={formConfig?.ageOfParticipants.required}
        name="ageOfParticipants"
        component={TextInput}
        label={label}
        addon={<InputGroup.Text>{t('bookingRequest.fields.ageOfParticipants.addon')}</InputGroup.Text>}
      />
    </Col>
  ) : null;
};

const renderTooltip = (props: OverlayInjectedProps) => (
  <Tooltip {...props} className="tooltip-bg-secondary">
    {i18n.t('disabledDay')}
  </Tooltip>
);
const renderDayContents = (day: number, isDisabled: boolean) => {
  if (isDisabled)
    return (
      <OverlayTrigger overlay={renderTooltip}>
        <div style={{ backgroundColor: '#f3f3f2', borderRadius: 4, cursor: 'default' }}>{day}</div>
      </OverlayTrigger>
    );
  return day;
};

export const DateInputWithBlockedDates = ({
  tourTopicId,
  targetGroupId,
  ...props
}: Omit<iDatePickerProps, 'field' | 'form' | 'onChange'> & {
  tourTopicId?: string | null;
  targetGroupId?: string | null;
}) => {
  const disabledCode = useMemo(() => {
    if (tourTopicId && targetGroupId) return { tourTopicId, targetGroupId };
    return;
  }, [targetGroupId, tourTopicId]);
  const { data, loading, error } = useBlockedDatesQuery({
    variables: { onlyActive: true, disabledCode },
  });

  const excludeDates = useMemo(() => {
    const startOfCurrentDay = startOfDay(new Date());
    return uniq(
      data?.blockedDates
        .map((blockedDate) =>
          eachDayOfInterval({
            start: new Date(blockedDate.startDate),
            end: new Date(blockedDate.endDate),
          }),
        )
        .flat()
        .filter((date) => date >= startOfCurrentDay),
    );
  }, [data?.blockedDates]);

  if (error) return <AlertGraphQLError error={error} />;
  return (
    <Field
      {...props}
      loading={loading}
      renderDayContents={(day: number, date: Date) =>
        renderDayContents(day, !!excludeDates.find((excludeDate) => excludeDate.getTime() === date.getTime()))
      }
      component={DatePickerFormik}
      excludeDates={excludeDates}
    />
  );
};

const PricingLevel = () => {
  const { formConfig, values } = useBookingContext();
  const { t } = useTranslation();
  return (
    <>
      <Alert variant="info">{t('bookingRequest.pricingFormAlert')}</Alert>
      <Row>
        <Col xs={12} sm={6}>
          <DateInputWithBlockedDates
            isRequired
            name="date"
            minDate={new Date()}
            label="bookingRequest.fields.date.label"
            placeholder="bookingRequest.fields.date.placeholder"
            icon={CalendarCheck}
            tourTopicId={values.tourTopicId}
            targetGroupId={values.targetGroupId}
          />
        </Col>
        <Col>
          <FastField
            isRequired
            name="time"
            component={TimePickerFormik}
            timeIntervals={30}
            dateFormat={TIME_FORMAT}
            minTime={localMinTime}
            maxTime={localMaxTime}
            nullable
            label="bookingRequest.fields.time.label"
            placeholder="bookingRequest.fields.time.placeholder"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <DateInputWithBlockedDates
            isRequired
            name="altDate"
            minDate={new Date()}
            label="bookingRequest.fields.altDate.label"
            placeholder="bookingRequest.fields.altDate.placeholder"
            icon={CalendarCheck}
            tourTopicId={values.tourTopicId}
            targetGroupId={values.targetGroupId}
          />
        </Col>
        <Col>
          <FastField
            isRequired
            name="altTime"
            component={TimePickerFormik}
            timeIntervals={30}
            dateFormat={TIME_FORMAT}
            minTime={localMinTime}
            maxTime={localMaxTime}
            nullable
            label="bookingRequest.fields.altTime.label"
            placeholder="bookingRequest.fields.altTime.placeholder"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TotalParticipantsInput />
        </Col>
        <TotalAccompanyingPersonsFormConfigInput formConfig={formConfig} colProps={{ sm: 6 }} />
        <AgeOfParticipantsInput formConfig={formConfig} colProps={{ sm: 6 }} />
      </Row>
      <br />
      <Offer />
      {/* <FastField
        isRequired
        name="pricesCheck"
        type="checkbox"
        component={Checkbox}
        label="bookingRequest.fields.pricesCheck.label"
      /> */}
      <FastField
        isRequired
        name="privacyPoliceCheck"
        type="checkbox"
        component={Checkbox}
        label={
          <Trans
            i18nKey="bookingRequest.fields.privacyPoliceCheck.label"
            components={{
              a: <a href={config.shadesToursPrivacyPolicy} target="_blank" rel="noopener noreferrer" />,
            }}
          />
        }
      />
    </>
  );
};

export default PricingLevel;
