import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { BookingkitConnectionForAppointmentFragmentDoc } from '../../bookingkit/generated/BookingkitConnectionForAppointmentFragment';
import { CustomerForAppointmentFragmentDoc } from '../../customer/generated/customerForAppointmentFragment';
import { GuideForAppointmentFragmentDoc } from '../../user/generated/guideForAppointmentFragment';
import { GuideFeedbackForAppointmentFragmentDoc } from '../../guideFeedback/generated/guideFeedbackForAppointmentFragment';
import { ProductForAppointmentFragmentDoc } from '../../product/generated/productForAppointmentFragment';
import { TourTopicBackgroundColorFragmentDoc } from '../../tourTopic/generated/tourTopicBackgroundColorFragment';
export type AppointmentFragment = {
  __typename?: 'Appointment';
  id: string;
  ageOfParticipants?: string | null;
  code?: string | null;
  endDate: string | Date;
  guideCheckInDate?: string | Date | null;
  infoForGuides?: string | null;
  infoForOffice?: SchemaTypes.AppointmentInfoForOffice | null;
  startDate: string | Date;
  status: SchemaTypes.AppointmentStatus;
  totalAccompanyingPersons?: number | null;
  totalGuides?: number | null;
  totalParticipants?: number | null;
  totalSlots: number;
  type: SchemaTypes.AppointmentType;
  bookingkitConnection?: {
    __typename?: 'AppointmentBookingkitConnection';
    dateId?: string | null;
    orderCode?: string | null;
    dateSyncedAt?: string | Date | null;
    dateSyncSuccess?: boolean | null;
    orderSuccess?: boolean | null;
    orderSyncedAt?: string | Date | null;
    errorName?: string | null;
    errorMessage?: string | null;
    ignoreError?: boolean | null;
  } | null;
  bookingRequest?: { __typename?: 'BookingRequest'; id: string } | null;
  customer?: {
    __typename?: 'Customer';
    id: string;
    salutation: SchemaTypes.Salutation;
    firstName: string;
    lastName: string;
    phoneNumber?: string | null;
    email: string;
    organization: { __typename?: 'CustomerOrganization'; id: string; name?: string | null };
  } | null;
  guide?: { __typename?: 'User'; id: string; profile: { __typename?: 'UserProfile'; fullName: string } } | null;
  guideFeedback?: {
    __typename?: 'GuideFeedback';
    totalParticipants: number;
    totalAccompanyingPersons?: number | null;
    comment: string;
    createdAt: string | Date;
  } | null;
  product?: {
    __typename?: 'Product';
    id: string;
    name: string;
    meetingPointName?: string | null;
    bookingkitSyncEnabled: boolean;
  } | null;
  tourTopic?: { __typename?: 'TourTopic'; id: string; backgroundColor: string } | null;
};

export type AppointmentFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const AppointmentFragmentDoc = gql`
  fragment Appointment on Appointment {
    id
    ageOfParticipants
    code
    endDate
    guideCheckInDate
    infoForGuides
    infoForOffice
    startDate
    status
    totalAccompanyingPersons
    totalGuides
    totalParticipants
    totalSlots
    type
    bookingkitConnection {
      ...BookingkitConnectionForAppointment
    }
    bookingRequest {
      id
    }
    customer {
      ...CustomerForAppointment
    }
    guide {
      ...GuideForAppointment
    }
    guideFeedback {
      ...GuideFeedbackForAppointment
    }
    product {
      ...ProductForAppointment
    }
    tourTopic {
      ...TourTopicBackgroundColor
    }
  }
  ${BookingkitConnectionForAppointmentFragmentDoc}
  ${CustomerForAppointmentFragmentDoc}
  ${GuideForAppointmentFragmentDoc}
  ${GuideFeedbackForAppointmentFragmentDoc}
  ${ProductForAppointmentFragmentDoc}
  ${TourTopicBackgroundColorFragmentDoc}
`;
