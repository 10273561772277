import React from 'react';
import { AppointmentFragment } from 'graphql/fragments/appointments/generated/appointmentFragment';
import { castArray, uniq } from 'lodash';
import ConfirmModal from 'components/common/Modal/ConfirmModal';
import { BookingkitConnectionForAppointmentFragment } from 'graphql/fragments/bookingkit/generated/BookingkitConnectionForAppointmentFragment';
import { UpdatePrivateTourAppointmentsStartDateMutation } from 'graphql/mutations/appointment/generated/UpdatePrivateTourAppointmentsStartDate';
import i18n from 'i18n';
import { set } from 'date-fns';

const values = {
  seconds: 0,
  milliseconds: 0,
};

const isDatesEqual = (date1: Date | string, date2: Date | string) =>
  set(new Date(date1), values).toISOString() === set(new Date(date2), values).toISOString();

export const checkUpdatingAppointment = (
  oldAppointment?: Pick<AppointmentFragment, 'startDate' | 'endDate'>,
  updatedAppointment?: AppointmentFragment,
) => {
  if (
    updatedAppointment &&
    updatedAppointment.bookingkitConnection &&
    (!oldAppointment ||
      !isDatesEqual(oldAppointment.startDate, updatedAppointment.startDate) ||
      !isDatesEqual(oldAppointment.endDate, updatedAppointment.endDate))
  )
    updateBookingkitAppointmentsModal(updatedAppointment.bookingkitConnection);
};

export const checkUpdatingBookingRequest = (
  oldAppointments: AppointmentFragment[],
  updatedAppointments: UpdatePrivateTourAppointmentsStartDateMutation['updateBookingRequestAppointments'] = [],
) => {
  const appointments = updatedAppointments.filter((updatedAppointment) => {
    const oldAppointment = oldAppointments.find((oldAppointment) => oldAppointment.id === updatedAppointment.id);
    if (
      !oldAppointment ||
      !isDatesEqual(oldAppointment.startDate, updatedAppointment.startDate) ||
      !isDatesEqual(oldAppointment.endDate, updatedAppointment.endDate)
    )
      return true;
  });
  console.log({ appointments, oldAppointments, updatedAppointments });

  updateBookingkitAppointmentsModal(
    appointments
      .map((appointment) => appointment.bookingkitConnection)
      .filter(Boolean) as BookingkitConnectionForAppointmentFragment[],
  );
};

const updateBookingkitAppointmentsModal = (
  data: BookingkitConnectionForAppointmentFragment | BookingkitConnectionForAppointmentFragment[],
) => {
  const bookingkitConnections = castArray(data);
  const orderCodes = uniq(
    bookingkitConnections.map((bookingkitConnection) => bookingkitConnection?.orderCode).filter(Boolean),
  );
  const dateIds = uniq(
    bookingkitConnections
      .map((bookingkitConnection) =>
        !orderCodes.includes(bookingkitConnection?.orderCode) ? bookingkitConnection?.dateId : null,
      )
      .filter(Boolean),
  );

  if (!orderCodes.length && !dateIds.length) return;
  return ConfirmModal({
    description: (
      <>
        <div>{i18n.t('bookingUpdateManuallyConfirmation.description')}</div>
        {orderCodes.map((orderCode) => (
          <div key={orderCode}>
            <a href={`https://eu5.bookingkit.de/order/index/${orderCode}`} target="_blank" rel="noreferrer">
              {i18n.t('bookingUpdateManuallyConfirmation.order')} {orderCode}
            </a>
          </div>
        ))}
        {dateIds.map((dateId) => (
          <div key={dateId}>
            <a href={`https://eu5.bookingkit.de/eventDate/view/${dateId}`} target="_blank" rel="noreferrer">
              {i18n.t('bookingUpdateManuallyConfirmation.eventDate')} {dateId}
            </a>
          </div>
        ))}
      </>
    ),
    hideCancel: true,
    okText: i18n.t('ok'),
  });
};
