import { BookingRequestInput, TargetGroupFieldConfig } from 'graphql/types.generated';
import { cloneDeep, concat, get, isNil, omit, pick, set } from 'lodash';
import { BookingInitialValuesProps } from '../../../pages/BookingRequestPage/BookingRequestPage.type';
import { defaultUpdateCustomerInitialValues } from './CustomerForm';
import { set as setDate } from 'date-fns';

export const defaultInitialValues: BookingInitialValuesProps = {
  tourTopicId: null,
  language: null,
  date: undefined,
  time: undefined,
  altDate: undefined,
  altTime: undefined,
  totalAccompanyingPersons: undefined,
  totalParticipants: undefined,
  ageOfParticipants: '',
  infoForGuides: '',
  howDidYouHear: '',
  // pricesCheck: false,
  privacyPoliceCheck: false,
  ...defaultUpdateCustomerInitialValues,
};

export const addDateAndTime = ({
  date,
  time,
}: {
  date: Date | null | undefined;
  time: Date | undefined;
}): Date | undefined => {
  if (!date || !time) return date ?? time;
  const newDate = setDate(new Date(date), {
    hours: time.getHours(),
    minutes: time.getMinutes(),
    seconds: time.getSeconds(),
    milliseconds: time.getMilliseconds(),
  });
  return newDate;
};

export const unsetBookingRequestValues = (
  bookingRequestInput: Partial<BookingRequestInput>,
  formConfig: TargetGroupFieldConfig[] | undefined,
) =>
  (
    formConfig
      ?.filter((field: TargetGroupFieldConfig) => !field.visible)
      .map((field: TargetGroupFieldConfig) => field.name) ?? []
  ).reduce((acc, name) => {
    if (!isNil(get(acc, name))) set(acc, name, null);
    return acc;
  }, cloneDeep(bookingRequestInput));

class BookingRequestDataTransformerClass {
  #keys = {
    additional: [/*'pricesCheck', */ 'privacyPoliceCheck'],
    organization: ['targetGroupId', 'address', 'buildingNumber', 'name', 'zip', 'city', 'uidNumber'],
    customer: ['salutation', 'firstName', 'lastName', 'email', 'phoneNumber'],
    time: ['date', 'time', 'dateTime', 'altDate', 'altTime', 'altDateTime'],
  };

  transformFormValuesToGraphQLParams = (
    values: BookingInitialValuesProps,
    formConfig: TargetGroupFieldConfig[] | undefined,
  ) => {
    const result = unsetBookingRequestValues(
      {
        ...(omit(
          values,
          concat(this.#keys.customer, this.#keys.organization, this.#keys.time, this.#keys.additional),
        ) as BookingRequestInput),
        date: 'dateTime' in values ? values.dateTime : addDateAndTime({ date: values.date, time: values.time }),
        altDate:
          'altDateTime' in values ? values.altDateTime : addDateAndTime({ date: values.altDate, time: values.altTime }),
        customer: {
          ...pick(values, this.#keys.customer),
          organization: pick(values, this.#keys.organization),
        },
      } as BookingRequestInput,
      formConfig,
    );
    return result as BookingRequestInput;
  };
}

export const BookingRequestDataTransformer = new BookingRequestDataTransformerClass();
