import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { BookingkitConnectionForAppointmentFragmentDoc } from '../../../fragments/bookingkit/generated/BookingkitConnectionForAppointmentFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RetryAppointmentSyncToBookingkitMutationVariables = SchemaTypes.Exact<{
  appointmentId: SchemaTypes.Scalars['String'];
}>;

export type RetryAppointmentSyncToBookingkitMutation = {
  __typename?: 'Mutation';
  retryAppointmentSyncToBookingkit: {
    __typename?: 'Appointment';
    id: string;
    bookingkitConnection?: {
      __typename?: 'AppointmentBookingkitConnection';
      dateId?: string | null;
      orderCode?: string | null;
      dateSyncedAt?: string | Date | null;
      dateSyncSuccess?: boolean | null;
      orderSuccess?: boolean | null;
      orderSyncedAt?: string | Date | null;
      errorName?: string | null;
      errorMessage?: string | null;
      ignoreError?: boolean | null;
    } | null;
  };
};

export const RetryAppointmentSyncToBookingkitDocument = gql`
  mutation RetryAppointmentSyncToBookingkit($appointmentId: String!) {
    retryAppointmentSyncToBookingkit(appointmentId: $appointmentId) {
      id
      bookingkitConnection {
        ...BookingkitConnectionForAppointment
      }
    }
  }
  ${BookingkitConnectionForAppointmentFragmentDoc}
`;
export type RetryAppointmentSyncToBookingkitMutationFn = Apollo.MutationFunction<
  RetryAppointmentSyncToBookingkitMutation,
  RetryAppointmentSyncToBookingkitMutationVariables
>;

/**
 * __useRetryAppointmentSyncToBookingkitMutation__
 *
 * To run a mutation, you first call `useRetryAppointmentSyncToBookingkitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryAppointmentSyncToBookingkitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryAppointmentSyncToBookingkitMutation, { data, loading, error }] = useRetryAppointmentSyncToBookingkitMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useRetryAppointmentSyncToBookingkitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RetryAppointmentSyncToBookingkitMutation,
    RetryAppointmentSyncToBookingkitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RetryAppointmentSyncToBookingkitMutation,
    RetryAppointmentSyncToBookingkitMutationVariables
  >(RetryAppointmentSyncToBookingkitDocument, options);
}
export type RetryAppointmentSyncToBookingkitMutationHookResult = ReturnType<
  typeof useRetryAppointmentSyncToBookingkitMutation
>;
export type RetryAppointmentSyncToBookingkitMutationResult =
  Apollo.MutationResult<RetryAppointmentSyncToBookingkitMutation>;
export type RetryAppointmentSyncToBookingkitMutationOptions = Apollo.BaseMutationOptions<
  RetryAppointmentSyncToBookingkitMutation,
  RetryAppointmentSyncToBookingkitMutationVariables
>;
