import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { BookingkitConnectionForAppointmentFragmentDoc } from '../../../fragments/bookingkit/generated/BookingkitConnectionForAppointmentFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PushBookingRequestAppointmentsToBookingkitMutationVariables = SchemaTypes.Exact<{
  bookingRequestId: SchemaTypes.Scalars['String'];
}>;

export type PushBookingRequestAppointmentsToBookingkitMutation = {
  __typename?: 'Mutation';
  pushBookingRequestAppointmentsToBookingkit: Array<{
    __typename?: 'Appointment';
    id: string;
    bookingkitConnection?: {
      __typename?: 'AppointmentBookingkitConnection';
      dateId?: string | null;
      orderCode?: string | null;
      dateSyncedAt?: string | Date | null;
      dateSyncSuccess?: boolean | null;
      orderSuccess?: boolean | null;
      orderSyncedAt?: string | Date | null;
      errorName?: string | null;
      errorMessage?: string | null;
      ignoreError?: boolean | null;
    } | null;
  }>;
};

export const PushBookingRequestAppointmentsToBookingkitDocument = gql`
  mutation PushBookingRequestAppointmentsToBookingkit($bookingRequestId: String!) {
    pushBookingRequestAppointmentsToBookingkit(bookingRequestId: $bookingRequestId) {
      id
      bookingkitConnection {
        ...BookingkitConnectionForAppointment
      }
    }
  }
  ${BookingkitConnectionForAppointmentFragmentDoc}
`;
export type PushBookingRequestAppointmentsToBookingkitMutationFn = Apollo.MutationFunction<
  PushBookingRequestAppointmentsToBookingkitMutation,
  PushBookingRequestAppointmentsToBookingkitMutationVariables
>;

/**
 * __usePushBookingRequestAppointmentsToBookingkitMutation__
 *
 * To run a mutation, you first call `usePushBookingRequestAppointmentsToBookingkitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePushBookingRequestAppointmentsToBookingkitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pushBookingRequestAppointmentsToBookingkitMutation, { data, loading, error }] = usePushBookingRequestAppointmentsToBookingkitMutation({
 *   variables: {
 *      bookingRequestId: // value for 'bookingRequestId'
 *   },
 * });
 */
export function usePushBookingRequestAppointmentsToBookingkitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PushBookingRequestAppointmentsToBookingkitMutation,
    PushBookingRequestAppointmentsToBookingkitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PushBookingRequestAppointmentsToBookingkitMutation,
    PushBookingRequestAppointmentsToBookingkitMutationVariables
  >(PushBookingRequestAppointmentsToBookingkitDocument, options);
}
export type PushBookingRequestAppointmentsToBookingkitMutationHookResult = ReturnType<
  typeof usePushBookingRequestAppointmentsToBookingkitMutation
>;
export type PushBookingRequestAppointmentsToBookingkitMutationResult =
  Apollo.MutationResult<PushBookingRequestAppointmentsToBookingkitMutation>;
export type PushBookingRequestAppointmentsToBookingkitMutationOptions = Apollo.BaseMutationOptions<
  PushBookingRequestAppointmentsToBookingkitMutation,
  PushBookingRequestAppointmentsToBookingkitMutationVariables
>;
