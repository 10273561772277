import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { AppointmentFragmentDoc } from '../../../fragments/appointments/generated/appointmentFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AppointmentsQueryVariables = SchemaTypes.Exact<{
  limit: SchemaTypes.Scalars['Int'];
  skip: SchemaTypes.Scalars['Int'];
  startDate?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['DateTime']>;
}>;

export type AppointmentsQuery = {
  __typename?: 'Query';
  appointments: {
    __typename?: 'PaginatedAppointmentResponse';
    total: number;
    items: Array<{
      __typename?: 'Appointment';
      id: string;
      ageOfParticipants?: string | null;
      code?: string | null;
      endDate: string | Date;
      guideCheckInDate?: string | Date | null;
      infoForGuides?: string | null;
      infoForOffice?: SchemaTypes.AppointmentInfoForOffice | null;
      startDate: string | Date;
      status: SchemaTypes.AppointmentStatus;
      totalAccompanyingPersons?: number | null;
      totalGuides?: number | null;
      totalParticipants?: number | null;
      totalSlots: number;
      type: SchemaTypes.AppointmentType;
      bookingkitConnection?: {
        __typename?: 'AppointmentBookingkitConnection';
        dateId?: string | null;
        orderCode?: string | null;
        dateSyncedAt?: string | Date | null;
        dateSyncSuccess?: boolean | null;
        orderSuccess?: boolean | null;
        orderSyncedAt?: string | Date | null;
        errorName?: string | null;
        errorMessage?: string | null;
        ignoreError?: boolean | null;
      } | null;
      bookingRequest?: { __typename?: 'BookingRequest'; id: string } | null;
      customer?: {
        __typename?: 'Customer';
        id: string;
        salutation: SchemaTypes.Salutation;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        email: string;
        organization: { __typename?: 'CustomerOrganization'; id: string; name?: string | null };
      } | null;
      guide?: { __typename?: 'User'; id: string; profile: { __typename?: 'UserProfile'; fullName: string } } | null;
      guideFeedback?: {
        __typename?: 'GuideFeedback';
        totalParticipants: number;
        totalAccompanyingPersons?: number | null;
        comment: string;
        createdAt: string | Date;
      } | null;
      product?: {
        __typename?: 'Product';
        id: string;
        name: string;
        meetingPointName?: string | null;
        bookingkitSyncEnabled: boolean;
      } | null;
      tourTopic?: { __typename?: 'TourTopic'; id: string; backgroundColor: string } | null;
    }>;
  };
};

export const AppointmentsDocument = gql`
  query Appointments($limit: Int!, $skip: Int!, $startDate: DateTime) {
    appointments(limit: $limit, skip: $skip, startDate: $startDate) {
      total
      items {
        ...Appointment
      }
    }
  }
  ${AppointmentFragmentDoc}
`;

/**
 * __useAppointmentsQuery__
 *
 * To run a query within a React component, call `useAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useAppointmentsQuery(
  baseOptions: Apollo.QueryHookOptions<AppointmentsQuery, AppointmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppointmentsQuery, AppointmentsQueryVariables>(AppointmentsDocument, options);
}
export function useAppointmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AppointmentsQuery, AppointmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppointmentsQuery, AppointmentsQueryVariables>(AppointmentsDocument, options);
}
export type AppointmentsQueryHookResult = ReturnType<typeof useAppointmentsQuery>;
export type AppointmentsLazyQueryHookResult = ReturnType<typeof useAppointmentsLazyQuery>;
export type AppointmentsQueryResult = Apollo.QueryResult<AppointmentsQuery, AppointmentsQueryVariables>;
export function refetchAppointmentsQuery(variables: AppointmentsQueryVariables) {
  return { query: AppointmentsDocument, variables: variables };
}
