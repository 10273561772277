export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: string | Date;
};

export type Appointment = {
  __typename?: 'Appointment';
  ageOfParticipants?: Maybe<Scalars['String']>;
  bookingRequest?: Maybe<BookingRequest>;
  bookingRequestId?: Maybe<Scalars['String']>;
  bookingkitConnection?: Maybe<AppointmentBookingkitConnection>;
  code?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  customer?: Maybe<Customer>;
  endDate: Scalars['DateTime'];
  guide?: Maybe<User>;
  guideCheckInDate?: Maybe<Scalars['DateTime']>;
  guideFeedback?: Maybe<GuideFeedback>;
  id: Scalars['ID'];
  infoForGuides?: Maybe<Scalars['String']>;
  infoForOffice?: Maybe<AppointmentInfoForOffice>;
  language?: Maybe<TourLanguage>;
  product?: Maybe<Product>;
  recurringEventId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  status: AppointmentStatus;
  targetGroup?: Maybe<TargetGroup>;
  totalAccompanyingPersons?: Maybe<Scalars['Float']>;
  totalGuides?: Maybe<Scalars['Int']>;
  totalParticipants?: Maybe<Scalars['Int']>;
  totalSlots: Scalars['Float'];
  tourTopic?: Maybe<TourTopic>;
  type: AppointmentType;
  updatedBy?: Maybe<Scalars['String']>;
};

export type AppointmentBookingkitConnection = {
  __typename?: 'AppointmentBookingkitConnection';
  appointmentId: Scalars['String'];
  dateId?: Maybe<Scalars['String']>;
  dateSyncSuccess?: Maybe<Scalars['Boolean']>;
  dateSyncedAt?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  errorName?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  ignoreError?: Maybe<Scalars['Boolean']>;
  orderCode?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  orderSuccess?: Maybe<Scalars['Boolean']>;
  orderSyncedAt?: Maybe<Scalars['DateTime']>;
  priceId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export enum AppointmentInfoForOffice {
  INVOICE_NOT_SENT = 'INVOICE_NOT_SENT',
  PARTIAL_INVOICE = 'PARTIAL_INVOICE',
  TICKET_NOT_SENT = 'TICKET_NOT_SENT',
}

export enum AppointmentStatus {
  AWAITS_FEEDBACK = 'AWAITS_FEEDBACK',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  NEW = 'NEW',
  REQUESTED = 'REQUESTED',
  SCHEDULED = 'SCHEDULED',
}

export enum AppointmentType {
  BOOKING_SLOT = 'BOOKING_SLOT',
  EVENT = 'EVENT',
  PRIVATE_TOUR = 'PRIVATE_TOUR',
}

export type AuthResponse = {
  __typename?: 'AuthResponse';
  accessToken: Scalars['String'];
  refreshToken?: Maybe<Scalars['String']>;
  user: User;
};

export type AutomationConfig = {
  __typename?: 'AutomationConfig';
  id: Scalars['ID'];
  isMaxToursDuringDayByTourTopic: Scalars['Boolean'];
  maxParticipants: Scalars['Int'];
  maxParticipantsInGroup: Scalars['Int'];
  maxToursDuringDay?: Maybe<Scalars['Int']>;
  participantsManualReviewRangeEnd: Scalars['Int'];
  participantsManualReviewRangeStart: Scalars['Int'];
};

export interface AutomationConfigInput {
  isMaxToursDuringDayByTourTopic: Scalars['Boolean'];
  maxParticipants: Scalars['Int'];
  maxParticipantsInGroup: Scalars['Int'];
  maxToursDuringDay?: InputMaybe<Scalars['Int']>;
  maxToursDuringDayByTourTopics?: InputMaybe<Array<TourTopicAutomationInput>>;
  participantsManualReviewRangeEnd: Scalars['Int'];
  participantsManualReviewRangeStart: Scalars['Int'];
}

export type AwaitsFeedback = {
  __typename?: 'AwaitsFeedback';
  delayMinutes: Scalars['Float'];
  message: Scalars['String'];
};

export interface AwaitsFeedbackInput {
  delayMinutes?: InputMaybe<Scalars['Float']>;
  message?: InputMaybe<Scalars['String']>;
}

export type BlockedDate = {
  __typename?: 'BlockedDate';
  blockedBy: User;
  disabledCodes?: Maybe<Array<DisabledCode>>;
  endDate: Scalars['DateTime'];
  id: Scalars['ID'];
  isAllCodes?: Maybe<Scalars['Boolean']>;
  startDate: Scalars['DateTime'];
};

export interface BlockedDateInput {
  disabledCodes?: InputMaybe<Array<DisabledCodeInput>>;
  endDate: Scalars['DateTime'];
  isAllCodes?: InputMaybe<Scalars['Boolean']>;
  startDate: Scalars['DateTime'];
}

export type BookingRequest = {
  __typename?: 'BookingRequest';
  ageOfParticipants?: Maybe<Scalars['String']>;
  altDate: Scalars['DateTime'];
  appointments: Array<Appointment>;
  automationDetails?: Maybe<Array<BookingRequestAutomationDetails>>;
  automationPassed?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customer: Customer;
  customerId: Scalars['ID'];
  date: Scalars['DateTime'];
  defaultProduct: Product;
  howDidYouHear?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  language: TourLanguage;
  lastEmail?: Maybe<EmailMessage>;
  status: BookingRequestStatus;
  totalAccompanyingPersons?: Maybe<Scalars['Int']>;
  totalAppointments: Scalars['Int'];
  totalEmailMessages: Scalars['Int'];
  totalParticipants: Scalars['Int'];
  tourTopic: TourTopic;
  tourTopicId: Scalars['ID'];
};

export interface BookingRequestAppointmentInput {
  ageOfParticipants?: InputMaybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  guideId?: InputMaybe<Scalars['String']>;
  infoForGuides?: InputMaybe<Scalars['String']>;
  infoForOffice?: InputMaybe<AppointmentInfoForOffice>;
  productId: Scalars['String'];
  startDate: Scalars['DateTime'];
  totalAccompanyingPersons?: InputMaybe<Scalars['Int']>;
  totalGuides?: InputMaybe<Scalars['Int']>;
  totalParticipants: Scalars['Int'];
}

export interface BookingRequestAppointmentPartialInput {
  ageOfParticipants?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  guideId?: InputMaybe<Scalars['String']>;
  infoForGuides?: InputMaybe<Scalars['String']>;
  infoForOffice?: InputMaybe<AppointmentInfoForOffice>;
  productId?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  totalAccompanyingPersons?: InputMaybe<Scalars['Int']>;
  totalGuides?: InputMaybe<Scalars['Int']>;
  totalParticipants?: InputMaybe<Scalars['Int']>;
}

export type BookingRequestAutomationDetails = {
  __typename?: 'BookingRequestAutomationDetails';
  details?: Maybe<Scalars['String']>;
  name: CriteriaType;
  passed: Scalars['Boolean'];
  payload?: Maybe<Scalars['String']>;
};

export type BookingRequestAvailableFilters = {
  __typename?: 'BookingRequestAvailableFilters';
  codes: Array<Scalars['String']>;
  guides: Array<User>;
  languages: Array<TourLanguage>;
  statuses: Array<BookingRequestStatus>;
};

export interface BookingRequestInput {
  ageOfParticipants?: InputMaybe<Scalars['String']>;
  altDate: Scalars['DateTime'];
  customer?: InputMaybe<CustomerInput>;
  date: Scalars['DateTime'];
  howDidYouHear?: InputMaybe<Scalars['String']>;
  infoForGuides?: InputMaybe<Scalars['String']>;
  language: TourLanguage;
  totalAccompanyingPersons?: InputMaybe<Scalars['Int']>;
  totalParticipants: Scalars['Int'];
  tourTopicId: Scalars['ID'];
}

export enum BookingRequestSort {
  NEWEST_REQUEST = 'NEWEST_REQUEST',
  UPCOMING_TOUR = 'UPCOMING_TOUR',
}

export enum BookingRequestStatus {
  ACCEPTED = 'ACCEPTED',
  CANCELED = 'CANCELED',
  EMAIL_SENT = 'EMAIL_SENT',
  NEW = 'NEW',
}

export interface BookingSlotAppointmentInput {
  ageOfParticipants?: InputMaybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  guideId?: InputMaybe<Scalars['String']>;
  infoForGuides?: InputMaybe<Scalars['String']>;
  infoForOffice?: InputMaybe<AppointmentInfoForOffice>;
  productId: Scalars['String'];
  startDate: Scalars['DateTime'];
  targetGroupId: Scalars['String'];
  totalSlots: Scalars['Int'];
}

export type BookingkitEvent = {
  __typename?: 'BookingkitEvent';
  id: Scalars['String'];
  prices: Array<BookingkitEventPrice>;
  title: Scalars['String'];
};

export type BookingkitEventPrice = {
  __typename?: 'BookingkitEventPrice';
  currency: Scalars['String'];
  id: Scalars['String'];
  public: Scalars['Int'];
  title: Scalars['String'];
  value: Scalars['Float'];
};

export type CheckIn = {
  __typename?: 'CheckIn';
  beforeMinutes: Scalars['Float'];
  message: Scalars['String'];
};

export interface CheckInInput {
  beforeMinutes?: InputMaybe<Scalars['Float']>;
  message?: InputMaybe<Scalars['String']>;
}

export interface CreateProductInput {
  bookingkitEventId?: InputMaybe<Scalars['String']>;
  bookingkitPriceId?: InputMaybe<Scalars['String']>;
  bookingkitSyncEnabled: Scalars['Boolean'];
  endPointAddress?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<TourLanguage>;
  meetingPointName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  startPointAddress?: InputMaybe<Scalars['String']>;
  tourTopicId?: InputMaybe<Scalars['ID']>;
  type: ProductType;
}

export interface CreateUserInput {
  email: Scalars['String'];
  fullName: Scalars['String'];
  password: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  role: Roles;
}

export enum CriteriaType {
  language = 'language',
  manualReviewRange = 'manualReviewRange',
  maxToursDuringDayT1 = 'maxToursDuringDayT1',
  totalParticipants = 'totalParticipants',
}

export type Customer = {
  __typename?: 'Customer';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  organization: CustomerOrganization;
  phoneNumber?: Maybe<Scalars['String']>;
  salutation: Salutation;
};

export interface CustomerInput {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  organization?: InputMaybe<CustomerOrganizationInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  salutation: Salutation;
}

export type CustomerOrganization = {
  __typename?: 'CustomerOrganization';
  address?: Maybe<Scalars['String']>;
  buildingNumber?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  targetGroup: TargetGroup;
  uidNumber?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export interface CustomerOrganizationInput {
  address?: InputMaybe<Scalars['String']>;
  buildingNumber?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  targetGroupId: Scalars['String'];
  uidNumber?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
}

export type DisabledCode = {
  __typename?: 'DisabledCode';
  targetGroup: TargetGroup;
  tourTopic: TourTopic;
};

export interface DisabledCodeInput {
  targetGroupId: Scalars['String'];
  tourTopicId: Scalars['String'];
}

export interface EditUserInfoInput {
  email: Scalars['String'];
  fullName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  role: Roles;
}

export type EmailMessage = {
  __typename?: 'EmailMessage';
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  delivered?: Maybe<Scalars['Boolean']>;
  emailClientResponse?: Maybe<Scalars['String']>;
  emailTemplate?: Maybe<EmailTemplate>;
  from: Scalars['String'];
  id: Scalars['ID'];
  sent: Scalars['Boolean'];
  subject: Scalars['String'];
  to: Scalars['String'];
  webhookEvents?: Maybe<Array<MailgunWebhookEvent>>;
};

export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  language: TourLanguage;
  name: Scalars['String'];
  subject: Scalars['String'];
  targetGroup?: Maybe<TargetGroup>;
  targetGroupId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export interface EmailTemplateInput {
  body: Scalars['String'];
  language: TourLanguage;
  name: Scalars['String'];
  subject: Scalars['String'];
}

export interface EventAppointmentInput {
  ageOfParticipants?: InputMaybe<Scalars['String']>;
  customer: CustomerInput;
  endDate: Scalars['DateTime'];
  guideId?: InputMaybe<Scalars['String']>;
  infoForGuides?: InputMaybe<Scalars['String']>;
  infoForOffice?: InputMaybe<AppointmentInfoForOffice>;
  productId: Scalars['String'];
  startDate: Scalars['DateTime'];
  targetGroupId: Scalars['String'];
  totalAccompanyingPersons?: InputMaybe<Scalars['Int']>;
  totalParticipants: Scalars['Int'];
}

export type GuideFeedback = {
  __typename?: 'GuideFeedback';
  comment: Scalars['String'];
  createdAt: Scalars['DateTime'];
  totalAccompanyingPersons?: Maybe<Scalars['Float']>;
  totalParticipants: Scalars['Float'];
};

export interface GuideFeedbackInput {
  comment?: InputMaybe<Scalars['String']>;
  totalAccompanyingPersons?: InputMaybe<Scalars['Int']>;
  totalParticipants: Scalars['Int'];
}

export type Localized = {
  __typename?: 'Localized';
  de: Scalars['String'];
  en: Scalars['String'];
};

export type MailgunWebhookEvent = {
  __typename?: 'MailgunWebhookEvent';
  date: Scalars['DateTime'];
  deliveryStatusCode?: Maybe<Scalars['Int']>;
  deliveryStatusDescription?: Maybe<Scalars['String']>;
  deliveryStatusMessage?: Maybe<Scalars['String']>;
  event: Scalars['String'];
  id: Scalars['String'];
  messageHeadersJSON: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
  recipient: Scalars['String'];
  severity?: Maybe<Scalars['String']>;
  variables: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addAppointmentToBookingRequest: BookingRequest;
  changePassword: Scalars['Boolean'];
  changeUserAccountActivationStatus: Scalars['Boolean'];
  checkInGuide: Appointment;
  createBlockedDate: BlockedDate;
  createBookingRequest: BookingRequest;
  createBookingSlotAppointment: Appointment;
  createEmailTemplate: Scalars['Boolean'];
  createEventAppointment: Appointment;
  createProduct: Product;
  createRecurringEvent: Scalars['Int'];
  createUser: User;
  deleteAppointment?: Maybe<Appointment>;
  deleteBlockedDate: Scalars['Boolean'];
  deleteEmailTemplate: Scalars['Boolean'];
  deleteProduct: Scalars['Boolean'];
  deleteUser: Scalars['Boolean'];
  editUserInfo: User;
  forgotPassword: Scalars['Boolean'];
  login: AuthResponse;
  logout: Scalars['Boolean'];
  provideGuideFeedback: Appointment;
  pushAppointmentToBookingkit: Appointment;
  pushBookingRequestAppointmentsToBookingkit: Array<Appointment>;
  registerDeviceForPushNotifications: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  retryAppointmentSyncToBookingkit: Appointment;
  runBookingRequestAutomationCriterias: BookingRequest;
  sendBookingRequestEmailTemplate?: Maybe<Scalars['Boolean']>;
  sendTestNotification: Scalars['Boolean'];
  toggleEmailNotifications: User;
  toggleIgnoreBookingkitErrorForAppointment: Appointment;
  token: AuthResponse;
  transitionBookingRequestToStatus: BookingRequest;
  unregisterDeviceFromPushNotifications: Scalars['Boolean'];
  updateAppointmentGuide: Appointment;
  updateAppointmentInfoForOffice: Appointment;
  updateAutomationConfig: AutomationConfig;
  updateBlockedDate: BlockedDate;
  updateBookingRequest: BookingRequest;
  updateBookingRequestAppointment: Appointment;
  updateBookingRequestAppointments: Array<Appointment>;
  updateBookingSlotAppointment: Appointment;
  updateCustomer: Customer;
  updateEmailTemplate: EmailTemplate;
  updateEventAppointment: Appointment;
  updateNotificationSettings: NotificationSettingsClass;
  updateProduct: Product;
};

export type MutationaddAppointmentToBookingRequestArgs = {
  data: BookingRequestAppointmentInput;
  id: Scalars['ID'];
};

export type MutationchangePasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type MutationchangeUserAccountActivationStatusArgs = {
  activationStatus: UserAccountActivationStatus;
  userId: Scalars['ID'];
};

export type MutationcheckInGuideArgs = {
  appointmentId: Scalars['ID'];
  date?: InputMaybe<Scalars['DateTime']>;
};

export type MutationcreateBlockedDateArgs = {
  data: BlockedDateInput;
};

export type MutationcreateBookingRequestArgs = {
  data: BookingRequestInput;
  skipAutomaticEmail?: InputMaybe<Scalars['Boolean']>;
};

export type MutationcreateBookingSlotAppointmentArgs = {
  data: BookingSlotAppointmentInput;
};

export type MutationcreateEmailTemplateArgs = {
  modifier: EmailTemplateInput;
};

export type MutationcreateEventAppointmentArgs = {
  data: EventAppointmentInput;
};

export type MutationcreateProductArgs = {
  data: CreateProductInput;
};

export type MutationcreateRecurringEventArgs = {
  data: RecurringEventInput;
};

export type MutationcreateUserArgs = {
  input: CreateUserInput;
};

export type MutationdeleteAppointmentArgs = {
  force?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};

export type MutationdeleteBlockedDateArgs = {
  id: Scalars['ID'];
};

export type MutationdeleteEmailTemplateArgs = {
  emailTemplateId: Scalars['ID'];
};

export type MutationdeleteProductArgs = {
  id: Scalars['ID'];
};

export type MutationdeleteUserArgs = {
  userId: Scalars['ID'];
};

export type MutationeditUserInfoArgs = {
  input: EditUserInfoInput;
  userId: Scalars['ID'];
};

export type MutationforgotPasswordArgs = {
  email: Scalars['String'];
};

export type MutationloginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  rememberMe: Scalars['Boolean'];
};

export type MutationprovideGuideFeedbackArgs = {
  appointmentId: Scalars['ID'];
  data: GuideFeedbackInput;
};

export type MutationpushAppointmentToBookingkitArgs = {
  appointmentId: Scalars['String'];
};

export type MutationpushBookingRequestAppointmentsToBookingkitArgs = {
  bookingRequestId: Scalars['String'];
};

export type MutationregisterDeviceForPushNotificationsArgs = {
  token: Scalars['String'];
};

export type MutationresetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type MutationretryAppointmentSyncToBookingkitArgs = {
  appointmentId: Scalars['String'];
};

export type MutationrunBookingRequestAutomationCriteriasArgs = {
  id: Scalars['ID'];
};

export type MutationsendBookingRequestEmailTemplateArgs = {
  bookingRequestId: Scalars['ID'];
  emailTemplate: SendEmailTemplateInput;
  test: Scalars['Boolean'];
};

export type MutationtoggleEmailNotificationsArgs = {
  emailNotificationsEnabled: Scalars['Boolean'];
};

export type MutationtoggleIgnoreBookingkitErrorForAppointmentArgs = {
  appointmentId: Scalars['String'];
  ignore: Scalars['Boolean'];
};

export type MutationtokenArgs = {
  refreshToken: Scalars['String'];
};

export type MutationtransitionBookingRequestToStatusArgs = {
  id: Scalars['ID'];
  status: BookingRequestStatus;
};

export type MutationunregisterDeviceFromPushNotificationsArgs = {
  token: Scalars['String'];
};

export type MutationupdateAppointmentGuideArgs = {
  guideId?: InputMaybe<Scalars['ID']>;
  id: Scalars['String'];
};

export type MutationupdateAppointmentInfoForOfficeArgs = {
  id: Scalars['String'];
  infoForOffice?: InputMaybe<Scalars['String']>;
};

export type MutationupdateAutomationConfigArgs = {
  data?: InputMaybe<AutomationConfigInput>;
};

export type MutationupdateBlockedDateArgs = {
  data: BlockedDateInput;
  id: Scalars['ID'];
};

export type MutationupdateBookingRequestArgs = {
  data: BookingRequestInput;
  id: Scalars['ID'];
};

export type MutationupdateBookingRequestAppointmentArgs = {
  appointmentId: Scalars['ID'];
  data: BookingRequestAppointmentPartialInput;
};

export type MutationupdateBookingRequestAppointmentsArgs = {
  bookingRequestId: Scalars['ID'];
  data: BookingRequestAppointmentPartialInput;
};

export type MutationupdateBookingSlotAppointmentArgs = {
  data: BookingSlotAppointmentInput;
  id: Scalars['String'];
};

export type MutationupdateCustomerArgs = {
  data: CustomerInput;
  id: Scalars['ID'];
};

export type MutationupdateEmailTemplateArgs = {
  emailTemplateId: Scalars['ID'];
  modifier: EmailTemplateInput;
};

export type MutationupdateEventAppointmentArgs = {
  data: EventAppointmentInput;
  id: Scalars['String'];
};

export type MutationupdateNotificationSettingsArgs = {
  data: NotificationSettingsInput;
};

export type MutationupdateProductArgs = {
  data: UpdateProductInput;
  id: Scalars['ID'];
};

export type NotificationSettingsClass = {
  __typename?: 'NotificationSettingsClass';
  awaitsFeedback: AwaitsFeedback;
  checkIn: CheckIn;
};

export interface NotificationSettingsInput {
  awaitsFeedback: AwaitsFeedbackInput;
  checkIn: CheckInInput;
}

export type PaginatedAppointmentResponse = {
  __typename?: 'PaginatedAppointmentResponse';
  items: Array<Appointment>;
  total: Scalars['Int'];
};

export type PaginatedBookingRequestResponse = {
  __typename?: 'PaginatedBookingRequestResponse';
  items: Array<BookingRequest>;
  total: Scalars['Int'];
};

export type PaginatedEmailMessageResponse = {
  __typename?: 'PaginatedEmailMessageResponse';
  items: Array<EmailMessage>;
  total: Scalars['Int'];
};

export type PaginatedEmailTemplateResponse = {
  __typename?: 'PaginatedEmailTemplateResponse';
  items: Array<EmailTemplate>;
  total: Scalars['Int'];
};

export type PaginatedUserResponse = {
  __typename?: 'PaginatedUserResponse';
  items: Array<User>;
  total: Scalars['Int'];
};

export type Product = {
  __typename?: 'Product';
  bookingkitEventId?: Maybe<Scalars['String']>;
  bookingkitPriceId?: Maybe<Scalars['String']>;
  bookingkitSyncEnabled: Scalars['Boolean'];
  endPointAddress?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  language?: Maybe<TourLanguage>;
  meetingPointName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  startPointAddress?: Maybe<Scalars['String']>;
  tourTopic?: Maybe<TourTopic>;
  type: ProductType;
};

export enum ProductType {
  BOOKING_SLOT = 'BOOKING_SLOT',
  EVENT = 'EVENT',
  PRIVATE_TOUR = 'PRIVATE_TOUR',
}

export type Query = {
  __typename?: 'Query';
  appointment: Appointment;
  appointments: PaginatedAppointmentResponse;
  automationConfig: AutomationConfig;
  availableFilters: BookingRequestAvailableFilters;
  blockedDates: Array<BlockedDate>;
  bookingRequest: BookingRequest;
  bookingRequests: PaginatedBookingRequestResponse;
  bookingkitEvent?: Maybe<BookingkitEvent>;
  bookingkitEvents?: Maybe<Array<BookingkitEvent>>;
  customer: Customer;
  emailMessages: PaginatedEmailMessageResponse;
  emailTemplates: PaginatedEmailTemplateResponse;
  guides: Array<User>;
  me: User;
  notificationSettings: NotificationSettingsClass;
  products: Array<Product>;
  publicTourDefaultTargetGroup: TargetGroup;
  renderBookingRequestEmailTemplate: Scalars['String'];
  targetGroup?: Maybe<TargetGroup>;
  targetGroups: Array<TargetGroup>;
  totalBookingRequests: Scalars['Int'];
  tourTopics: Array<TourTopic>;
  users: PaginatedUserResponse;
};

export type QueryappointmentArgs = {
  id: Scalars['String'];
};

export type QueryappointmentsArgs = {
  bookingRequestId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  filterByCurrentUser?: InputMaybe<Scalars['Boolean']>;
  hasCheckInDate?: InputMaybe<Scalars['Boolean']>;
  limit: Scalars['Int'];
  readyToCheckIn?: InputMaybe<Scalars['Boolean']>;
  skip: Scalars['Int'];
  startDate?: InputMaybe<Scalars['DateTime']>;
  statuses?: InputMaybe<Array<AppointmentStatus>>;
};

export type QueryblockedDatesArgs = {
  disabledCode?: InputMaybe<DisabledCodeInput>;
  onlyActive?: InputMaybe<Scalars['Boolean']>;
};

export type QuerybookingRequestArgs = {
  id: Scalars['String'];
};

export type QuerybookingRequestsArgs = {
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  sort?: InputMaybe<BookingRequestSort>;
  statuses?: InputMaybe<Array<BookingRequestStatus>>;
};

export type QuerybookingkitEventArgs = {
  bookingkitEventId: Scalars['String'];
};

export type QuerycustomerArgs = {
  id: Scalars['String'];
};

export type QueryemailMessagesArgs = {
  bookingRequestId?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
};

export type QueryemailTemplatesArgs = {
  limit: Scalars['Int'];
  skip: Scalars['Int'];
};

export type QueryproductsArgs = {
  appointmentType?: InputMaybe<AppointmentType>;
};

export type QueryrenderBookingRequestEmailTemplateArgs = {
  bookingRequestId: Scalars['ID'];
  emailTemplateId: Scalars['ID'];
};

export type QuerytargetGroupArgs = {
  targetGroupId: Scalars['String'];
};

export type QueryusersArgs = {
  filter: UserFilterArgs;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
};

export interface RecurringEventInput {
  endDate: Scalars['DateTime'];
  endTimeString: Scalars['String'];
  infoForGuides?: InputMaybe<Scalars['String']>;
  infoForOffice?: InputMaybe<AppointmentInfoForOffice>;
  productId: Scalars['String'];
  startDate: Scalars['DateTime'];
  startTimeString: Scalars['String'];
  totalSlots: Scalars['Float'];
  weekDays: Array<Scalars['Int']>;
}

export enum Roles {
  ADMIN = 'ADMIN',
  GUIDE = 'GUIDE',
}

export enum Salutation {
  mr = 'mr',
  mrs = 'mrs',
}

export interface SendEmailTemplateInput {
  body: Scalars['String'];
  id: Scalars['ID'];
  language: TourLanguage;
  subject: Scalars['String'];
}

export type TargetGroup = {
  __typename?: 'TargetGroup';
  bookable: Scalars['Boolean'];
  bookingFormConfig?: Maybe<TargetGroupBookingFormConfig>;
  code: Scalars['String'];
  id: Scalars['ID'];
  name: Localized;
};

export type TargetGroupBookingFormConfig = {
  __typename?: 'TargetGroupBookingFormConfig';
  bookingFormImageUrl?: Maybe<Scalars['String']>;
  description: Localized;
  fieldConfig: Array<TargetGroupFieldConfig>;
  formSubmissionsDisabled: Scalars['Boolean'];
  formSubmissionsDisabledFrom?: Maybe<Scalars['DateTime']>;
  formSubmissionsDisabledImageUrl: Scalars['String'];
  formSubmissionsDisabledMessage: Scalars['String'];
  formSubmissionsDisabledTo?: Maybe<Scalars['DateTime']>;
  iconUrl: Scalars['String'];
  offerDescription: Localized;
};

export type TargetGroupFieldConfig = {
  __typename?: 'TargetGroupFieldConfig';
  name: Scalars['String'];
  required: Scalars['Boolean'];
  visible: Scalars['Boolean'];
};

export enum TourLanguage {
  de = 'de',
  en = 'en',
}

export type TourTopic = {
  __typename?: 'TourTopic';
  backgroundColor: Scalars['String'];
  code: Scalars['String'];
  iconUrl: Scalars['String'];
  id: Scalars['ID'];
  maxToursDuringDay?: Maybe<Scalars['Int']>;
  name: Localized;
  productDE?: Maybe<Product>;
  productEN?: Maybe<Product>;
  productId?: Maybe<Localized>;
};

export interface TourTopicAutomationInput {
  id: Scalars['String'];
  maxToursDuringDay: Scalars['Int'];
}

export interface UpdateProductInput {
  bookingkitEventId?: InputMaybe<Scalars['String']>;
  bookingkitPriceId?: InputMaybe<Scalars['String']>;
  bookingkitSyncEnabled: Scalars['Boolean'];
  endPointAddress?: InputMaybe<Scalars['String']>;
  meetingPointName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  startPointAddress?: InputMaybe<Scalars['String']>;
}

export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime'];
  deactivated?: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  emailNotificationsEnabled: Scalars['Boolean'];
  id: Scalars['ID'];
  lastActivityDate?: Maybe<Scalars['DateTime']>;
  profile: UserProfile;
  role: Roles;
};

export enum UserAccountActivationStatus {
  activated = 'activated',
  deactivated = 'deactivated',
}

export interface UserFilterArgs {
  role?: InputMaybe<Roles>;
}

export type UserProfile = {
  __typename?: 'UserProfile';
  fullName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};
