const translations = {
  translation: {
    errors: {
      appointmentTypeNotFound: 'Appointment type not found',
      targetGroupsTypeNotFound: 'Target groups not found',
      customerNotFound: 'Customer not found',
      bookingRequestNotFound: 'Booking request not found',
      serverConnectionError: 'Failed connection to the server',
    },
    disabledDay: 'This day is not available',
    closeConfirmation: 'If you close the modal, entered data will be lost. Do you want to close the modal?',
    notApplicable: 'N/A',
    yes: 'Yes',
    no: 'No',
    continue: 'Continue',
    cancel: 'Cancel',
    ok: 'Ok',
    edit: 'Edit',
    delete: 'Delete',
    create: 'Create',
    save: 'Save',
    apply: 'Apply',
    reset: 'Reset',
    close: 'Close',
    send: 'Send',
    empty: 'There is no data',
    role: {
      ADMIN: 'Admin',
      GUIDE: 'Guide',
    },
    appointmentType: {
      BOOKING_SLOT: 'Public Tour',
      EVENT: 'Event',
      PRIVATE_TOUR: 'Private Tour',
    },
    bookingRequestSort: {
      NEWEST_REQUEST: 'Newest request',
      UPCOMING_TOUR: 'Upcoming tour',
    },
    productType: {
      RECURRING_BOOKING_SLOT: 'Recurring public tour',
      BOOKING_SLOT: 'Public Tour',
      EVENT: 'Event',
      PRIVATE_TOUR: 'Private Tour',
    },
    infoForOffice: {
      INVOICE_NOT_SENT: 'Invoice not sent',
      PARTIAL_INVOICE: 'Partial invoice',
      TICKET_NOT_SENT: 'Ticket not sent',
    },
    fontSize: {
      small: 'Small',
      normal: 'Normal',
      large: 'Large',
      huge: 'Huge',
    },
    salutations: {
      mrs: 'Mrs',
      mr: 'Mr',
    },
    locale: {
      en: 'English',
      de: 'German',
    },
    bookingRequestStatus: {
      ACCEPTED: 'Accepted',
      CANCELED: 'Canceled',
      EMAIL_SENT: 'Requested',
      NEW: 'New',
    },
    appointmentStatus: {
      NEW: 'New',
      REQUESTED: 'Requested',
      SCHEDULED: 'Scheduled',
      AWAITS_FEEDBACK: 'Awaits feedback',
      COMPLETED: 'Completed',
      CANCELED: 'Canceled',
    },
    pageTitles: {
      appointments: 'Appointments',
      bookingRequests: 'Booking requests',
      emailTemplates: 'Email templates',
      userManagement: 'User management',
      systemConfig: 'System config',
      logout: 'Logout',
      myProfile: 'My Profile',
    },
    RichEditor: {
      link: {
        modalTitle: 'Add a link',
        fields: {
          name: {
            label: 'Name',
          },
          value: {
            label: 'URL',
            invalid: 'Value must be a valid URL',
            description:
              '<div><div>Example:</div><div>https://google.com - insert a web site link</div><div>mailto:someone@gmail.com - insert a create email link</div><div>tel:+49123456789 - insert a mobile number</div></div>',
          },
        },
      },
      image: {
        modalTitle: 'Add an image',
        fields: {
          value: {
            label: 'URL',
            invalid: 'Value must be a valid URL',
            placeholder: 'https://....',
          },
        },
      },
    },
    appointments: {
      view: 'View',
      tour: 'Tour',
      createEventAppointmentSuccess: 'Event appointment created',
      updateEventAppointmentSuccess: 'Event appointment updated',
      createRecurringEventSuccess_one: '{{count}} recurring appointment created',
      createRecurringEventSuccess_other: '{{count}} recurring appointments created',
      createAppointmentSuccess: 'Appointment created',
      createBookingRequestSuccess: 'Booking request created',
      provideGuideFeedbackSuccess: 'Guide feedback provided',
      deleteAppointmentSuccess: 'Appointment deleted',
      updateAppointmentSuccess: 'Appointment updated',
      cancelApppointmentSuccess: 'Appointment canceled',
      updateAppointmentInfoForOfficeSuccess: 'Appointment info for office updated',
      updateAppointmentGuideSuccess: 'Appointment guide updated',
      bookingRequestAlert: 'Booking requests submitted in the <a>booking form</a>.',
      resetFilters: 'Reset filters',
      privateTourAlert:
        'The appointment is a part of a booking request that may include multiple groups. If you are looking for changing the customer information, then <a>click here</a>. Please note that the customer details will be updated for all the related groups.',
      requestsTab: 'Form Requests',
      appointmentsTab: 'Appointments',
      appointmentModal: 'Appointment: {{type}}',
      createBookingRequestModal: 'Create Booking Request',
      appointmentInfoForOfficeModal: 'Update appointment info for office',
      appointmentGuideModal: 'Update appointment guide',
      provideGuideFeedbackModal: 'Provide guide feedback',
      guides_one: 'guide',
      guides_other: 'guides',
      deleteConfirmation: {
        title: 'Delete appointment',
        description: 'Do you want to remove this appointment?',
      },
      cancelConfirmation: {
        title: 'Cancel appointment',
        description: 'Do you want to cancel the appointment??',
      },
      columns: {
        date: 'Date',
        type: 'Type',
        time: 'Time',
        code: 'Code',
        organization: 'Organization',
        contact: 'Contact',
        totalParticipants: 'TN',
        totalAccompanyingPersons: 'BN',
        status: 'Status',
        infoForOffice: 'Info for office',
        language: 'Lang',
        guides: 'Guides',
        lastEmailDate: 'Offer date',
        service: 'Service',
        guide: 'Guide',
        product: 'Product',
        guideFeedback: 'Guide feedback',
        meetingPlace: 'Meeting place',
        infoForGuide: 'Info for Guide',
        bookingkit: 'Bookingkit',
      },
      bookingkitCell: {
        syncNotEnabledForProductTooltip: 'Bookingkit sync is not enabled for the product {{name}}',
        syncNotEnabledForProductBadge: 'Not enabled',
        openDateInBookingkitLink: 'Open',
        pushedSuccessfullyNotification: 'Appointment pushed successfully to Bookingkit',
        syncScheduled: 'Sync scheduled. Please refresh page to see results',
        manualPushButton: 'Push',
        ignoreErrorButton: 'Ignore',
        retrySyncButton: 'Retry sync',
        status: {
          PUSHED: 'Pushed',
          NOT_PUSHED: 'Not pushed',
          ERROR: 'Error',
          UNKNOWN: 'Unknown',
          IGNORED: 'Ignored',
        },
      },
      bookingkitDateCancelConfirmation: {
        title: 'Cancel appointment in Bookingkit',
        description:
          'This appointment is linked to a Bookingkit. You need to manually cancel it in the Bookingkit portal.',
        openBookingkitLink: 'Open Bookingkit',
        okText: 'Done',
      },
      fields: {
        totalGuides: {
          label: 'Number of guides',
        },
        guideFeedback: {
          label: 'Feedback:',
          checkIn: 'Check-in:',
        },
        comment: {
          label: 'Comment',
        },
        timeRange: {
          label: 'Time',
          endTime: 'End time',
          startTime: 'Start time',
        },
        dateRange: {
          label: 'Period',
        },
        date: {
          label: 'Date',
          placeholder: 'Select date',
          description: '',
          endDate: 'End date',
          startDate: 'Start date',
        },
        infoForOffice: {
          label: 'Info for office',
          placeholder: 'Select info for office',
        },
        search: {
          placeholder: 'Search...',
        },
        language: {
          label: 'Tour Language',
        },
        code: {
          label: 'Code',
          description: 'Code is auto-filled based on Target Group and Tour Topic',
        },
        status: {
          label: 'Status',
        },
        guideId: {
          label: 'Guide',
          description: 'Guide can be empty – it can be added anytime',
        },
        tourTopicId: {
          label: 'Tour topic',
          description: 'Tour topic is auto-filled based on product',
        },
        productId: {
          label: 'Product',
          description: 'The list includes only products of type "Private tour"',
        },
        appointmentLanguage: {
          label: 'Language',
          description: 'Language is auto-filled based on product',
        },
        targetGroupId: {
          label: 'Target group',
          description: '',
        },
        meetingPlace: {
          label: 'Meeting place',
          description: 'Meeting place is auto-filled based on product',
        },
        weekDays: {
          label: 'Week Days',
        },
      },
    },
    bookingUpdateManuallyConfirmation: {
      description: 'Please send the booking update manually to the client:',
      order: 'Order',
      eventDate: 'Event date',
    },
    bookingRequestView: {
      confirm: 'Confirm request',
      reopen: 'Reopen request',
      cancel: 'Cancel request',
      updateBookingRequestSuccess: 'Booking request updated',
      updateCustomerSuccess: 'Customer updated',
      appointments: 'Appointments',
      title: 'Booking Request',
      request: 'Request',
      customer: 'Customer',
      sentEmails: 'Sent Emails',
      newEmail: 'Send Offer',
      viewFullBody: 'View full message',
      applyDateConfirmation: {
        title: 'Apply date',
        description: 'Do you want to apply the clicked date to the related appointments?',
        success: 'Date applied',
      },
      updateCustomerModal: {
        title: 'Edit Customer information',
        organization: 'Organization',
        contactPerson: 'Contact person',
      },
      newEmailModalTitle: 'Send email',
      newEmailModal: {
        sendTestEmailButton: 'Test email',
        emailSent: 'Email sent',
      },
      updateBookingRequestModal: 'Edit Booking Request',
      columns: {
        date: 'Date',
        time: 'Time',
        code: 'Code',
        organization: 'Organization',
        contact: 'Contact',
        totalParticipants: 'TN',
        totalAccompanyingPersons: 'BN',
        status: 'Status',
        language: 'Lang',
        guides: 'Guides',
        emails: 'Emails',
        service: 'Service',
        bookingKit: 'Bookingkit',
        meetingPlace: 'Meeting place',
        product: 'Product',
        template: 'Template',
        subject: 'Subject',
        body: 'Body',
        delivery: 'Delivery',
      },
      emailMessage: {
        deliveryDetails: {
          status: 'Status',
          statusDelivered: 'Not delivered',
          statusNotDelivered: 'Delivered',
          statusUnknown: 'Unknown',
          serverResponse: 'Server response',
          events: 'Events',
        },
      },
      fields: {
        skipAutomaticEmail: {
          label: 'Skip automatic email',
        },
        targetGroupId: {
          label: 'Target group',
        },
        to: {
          label: 'To',
        },
        organizationName: {
          label: 'Organization name',
        },
        tourTopicId: {
          label: 'Tour topic',
        },
        name: {
          label: 'Name',
        },
        email: {
          label: 'Email address',
        },
        phoneNumber: {
          label: 'Mobile number',
        },
        street: {
          label: 'Street',
        },
        buildingNumber: {
          label: 'Building number',
        },
        zip: {
          label: 'ZIP',
        },
        city: {
          label: 'City',
        },
        uidNumber: {
          label: 'UID or ZVR number ',
        },
        internalId: {
          label: 'Internal ID',
        },
        code: {
          label: 'Code',
        },
        createdAt: {
          label: 'Created at',
        },
        dateTime: {
          label: 'Date and time',
        },
        language: {
          label: 'Tour Language',
        },
        altDateTime: {
          label: 'Alt date and time',
        },
        howDidYouHear: {
          label: 'How did you hear?',
        },
        totalParticipants: {
          label: 'Number of participants',
        },
        status: {
          label: 'Status',
        },
        totalAccompanyingPersons: {
          label: 'Accompanying persons',
        },
        automationDetails: {
          label: 'Automation details',
          refreshAutomationDetailsSuccess: 'Automation details refreshed',
        },
        ageOfParticipants: {
          label: 'Age of participants',
        },
      },
    },
    emailTemplates: {
      isTargetGroupTemplate: 'Tour',
      createEmailTemplateSuccess: 'Email template created',
      deleteConfirmation: {
        title: 'Delete email template',
        description: 'Do you want to remove this email template?',
        success: 'Email template deleted',
      },
      title: 'Email templates',
      edit: {
        modalTitle: 'Edit email template',
        button: 'Edit',
        successfullyUpdated: 'Email template updated',
      },
      fields: {
        language: {
          label: 'Language',
        },
        name: {
          label: 'Name',
        },
        subject: {
          label: 'Subject',
        },
        body: {
          label: 'Body',
        },
        emailTemplateId: {
          label: 'Template',
        },
      },
    },
    Placeholder: {
      title: 'Placeholder',
    },
    systemConfig: {
      bookingkit: 'Bookingkit',
      all: 'ALL',
      createProductModal: 'Create product',
      updateProductModal: 'Edit product',
      createProductSuccess: 'Product created',
      updateProductSuccess: 'Product edited',
      deleteProductConfirmation: {
        title: 'Delete product',
        description: 'Do you want to delete this product?',
        success: 'Product deleted',
      },
      deleteBlockedDateConfirmation: {
        title: 'Delete blocked date',
        description: 'Do you want to delete this blocked date?',
        success: 'Blocked date deleted',
      },
      createBlockedDateSuccess: 'Blocked date created',
      updateBlockedDateSuccess: 'Blocked date updated',
      updateAutomationConfigSuccess: 'Automation config updated',
      resetAutomationConfigSuccess: 'Automation config reset',
      products: 'BookingKit Products',
      organizationTypes: 'Target groups',
      tourTopics: 'Tour topics',
      meetingPlaces: 'Meeting place options',
      services: 'Service options',
      dateTime: 'Date & Time configuration',
      title: 'System config',
      automation: 'Automation',
      bookingRequest: 'Booking form submission ',
      tours: 'Tours',
      blockDates: 'Block dates',
      startTime: 'Start time configuration',
      save: 'Save changes',
      revert: 'Revert changes',
      columns: {
        integrations: 'Integrations',
        type: 'Type',
        place: 'Place',
        language: 'Lang',
        tourTopic: 'Tour topic',
        name: 'Name',
        abbreviation: 'Abbreviation',
        startPointAddress: 'Start point address',
        endPointAddress: 'End point address',
        startDate: 'Start date',
        endDate: 'End date',
        disabledCodes: 'Disabled codes',
        blockedBy: 'Blocked by',
      },
      fields: {
        bookingkitEventId: {
          label: 'Bookingkit Event',
        },
        bookingkitPriceId: {
          label: 'Price',
        },
        bookingkitSyncEnabled: {
          label: 'Sync with Bookingkit',
        },
        name: {
          label: 'Name',
        },
        type: {
          label: 'Type',
        },
        meetingPointName: {
          label: 'Place',
        },
        startPointAddress: {
          label: 'Start point address',
        },
        endPointAddress: {
          label: 'End point address',
        },
        maxParticipants: {
          label: 'MaxParticipants',
          description: 'Max number of participants to automatically accept',
        },
        participantsManualReviewRangeStart: {
          label: 'ParticipantsManualReviewRange from',
          description: 'if participants in this range, then manual review required',
        },
        participantsManualReviewRangeEnd: {
          label: 'ParticipantsManualReviewRange to',
        },
        isMaxToursDuringDayByTourTopic: {
          label: 'MaxToursDuringDay by Tour Topic',
        },
        maxToursDuringDay: {
          label: 'MaxToursDuringDay',
          tourTopicLabel: '"{{name}}" MaxToursDuringDay',
          description: 'Number of existing tours during the day to automatically accept',
        },
        maxParticipantsInGroup: {
          label: 'MaxParticipantsInGroup',
          description: 'Critical number of participant divider',
        },
        disabledSubmission: {
          label: 'Disable booking form for new submissions',
          description: 'Check this box to prevent customers from booking tours using form',
        },
        disabledSubmissionMessage: {
          label: 'Disabled booking form message',
        },
        blockedDateRange: {
          label: 'Block dates between',
          startPlaceholder: 'Start date',
          endPlaceholder: 'End date',
          startName: 'Start date',
          endName: 'End date',
        },
        disabledCodes: {
          label: 'Disabled codes',
          placeholder: 'Select disabled codes',
        },
        isAllCodes: {
          label: 'Block all codes',
        },
      },
    },
    userManagement: {
      duplicatedErrorMessage: 'A user with the given email is already registered',
      createUserSuccess: 'User created',
      updateUserSuccess: 'User updated',
      deleteConfirmation: {
        title: 'Delete user',
        description: 'Do you want to delete this user?',
        success: 'User deleted',
      },
      create: 'Create User',
      update: 'Update User',
      title: 'User Management',
      columns: {
        actions: 'Actions',
        active: 'Active',
        email: 'Email',
        fullName: 'Full name',
        lastActivityDate: 'Last login',
        phoneNumber: 'Phone',
      },
      fields: {
        fullName: {
          label: 'Full name',
          placeholder: 'Enter your full name',
        },
        phoneNumber: {
          label: 'Mobile number',
        },
        email: {
          label: 'Email address',
          placeholder: 'Enter your email',
        },
        password: {
          label: 'Password',
          placeholder: 'Password',
        },
        role: {
          label: 'Role',
          placeholder: 'Select role',
        },
      },
    },
    userProfile: {
      changeLocaleSelectLabel: 'User interface locale',
      changePassword: {
        success: 'Password changed',
        button: 'Change password',
        modalTitle: 'Change password',
      },
      emailNotificationsToggle: 'Email notifications',
      registered: 'Registered:',
      title: 'User profile',
      fields: {
        password: {
          label: 'Password',
        },
        newPassword: {
          label: 'New password',
        },
        repeatedNewPassword: {
          label: 'Repeat new password',
          noMatch: 'Passwords must match',
        },
      },
    },
    bookingRequest: {
      goBackConfirmation: 'If you go back, the selected data will be lost. Do you want to go back?',
      header: 'Booking Form (step {{currentIndex}}/{{stepNumber}})',
      offerTitle: '',
      organizationTitle: 'Organization address / Billing address',
      contactTitle: 'Contact person',
      completed: {
        title: 'Thank You!',
        description: 'We will get back to you by email as soon as possible!',
        goBack: 'Back to home page',
      },
      pricingFormAlert:
        'Caution: during the last two weeks before summer vacations our starting times are 8am, 11am, 2pm and 5pm. Please make sure that you use these suggested time slots for your excursion during those two weeks.',
      fields: {
        targetGroupId: {
          label: 'I am organizing an excursion for',
          name: 'Target group',
          placeholder: 'Please select',
        },
        // pricesCheck: {
        //   label: 'I confirm to agree to the information and price above.',
        //   required: 'Prices check is required',
        // },
        infoForGuides: {
          label: 'Information for the guides',
          description: 'Please only information for the guide, NO questions.',
        },
        privacyPoliceCheck: {
          label: 'I have read and agree to <a>Privacy Policy</a>',
          required: 'Privacy Policy check is required',
        },
        tourTopicId: {
          label: 'I would like an excursion on the subject',
          name: 'Tour topic',
        },
        language: {
          label: 'Guided language of the tour',
        },
        date: {
          label: 'Date',
          placeholder: 'Select date',
        },
        time: {
          label: 'Time',
          placeholder: 'Select time',
        },
        altDate: {
          label: 'Alternative Date',
          placeholder: 'Select date',
        },
        altTime: {
          label: 'Alternative Time',
          placeholder: 'Select time',
        },
        totalSlots: {
          label: 'Number of booking slots',
        },
        totalAccompanyingPersons: {
          label: 'Number of accompanying persons',
        },
        totalParticipants: {
          label: 'Number of participants',
        },
        ageOfParticipants: {
          label: 'Age of participants',
          addon: 'years old',
        },
        salutation: {
          label: 'Salutation',
          placeholder: 'Please select',
        },
        firstName: {
          label: 'First name',
        },
        lastName: {
          label: 'Last name',
        },
        email: {
          label: 'Email address',
        },
        phoneNumber: {
          label: 'Mobile number',
          invalid: 'Mobile number is not valid',
        },
        name: {
          label: 'Organization name',
        },
        address: {
          label: 'Street',
        },
        buildingNumber: {
          label: 'Building number',
        },
        zip: {
          label: 'ZIP code',
        },
        city: {
          label: 'City',
        },
        uidNumber: {
          label: 'UID or ZVR number',
        },
        howDidYouHear: {
          label: 'How did you hear about SHADES TOURS?',
        },
      },
    },
    login: {
      title: 'Log in to your account',
      subtitle: 'Welcome back! Please enter your details.',
      fields: {
        email: {
          label: 'Email',
          placeholder: 'Enter your email',
        },
        password: {
          label: 'Password',
          placeholder: 'Password',
        },
        rememberMe: {
          label: 'Remember me',
        },
      },
    },
  },
};

export default translations;

export const backendErrors = {
  // appointment
  APPOINTMENT_NOT_FOUND: 'Appointment not found',
  APPOINTMENT_INCORRECT_TYPE: 'Appointment type is incorrect',
  APPOINTMENT_WITHOUT_CUSTOMER: 'Appointment without a client',
  // automation config
  AUTOMATION_CONFIG_NOT_FOUND: 'Automation not found',
  // booking request
  BOOKING_REQUEST_NOT_FOUND: 'Booking request not found',
  BAD_BOOKING_REQUEST: 'Can you check the booking request form again? There are inconsistencies',
  // customer
  CUSTOMER_NOT_FOUND: 'Customer not found',
  CUSTOMER_NOT_FOUND_IN_INPUT: 'Customer is required',
  // email template
  EMAIL_TEMPLATE_NOT_FOUND: 'Email template not found',
  EMAIL_TEMPLATE_FOR_TOUR_TOPIC_CANT_BE_REMOVED:
    'You cannot remove the email template because it is used in system config',
  // guide feedback
  GUIDE_FEEDBACK_NOT_FOUND: 'Guide feedback not found',
  // product
  PRODUCT_NOT_FOUND: 'Product not found',
  PRODUCT_FOR_TOUR_TOPIC_CANT_BE_REMOVED: 'You cannot remove the product because it is used in system config',
  BAD_PRODUCT_INPUT: 'Can you check the product form again? There are inconsistencies',
  PROHIBIT_CHANGE_PUSHED_APPOINTMENT_PRODUCT:
    'You cannot update the product field because the appointment is pushed to the Bookingkit',
  // target group
  TARGET_GROUP_NOT_FOUND: 'Target group not found',
  // tour topic
  TOUR_TOPIC_NOT_FOUND: 'Tour topic not found',
  TOUR_TOPIC_WITHOUT_PRODUCT: 'Tour topic without a product',
  // user
  USER_NOT_FOUND: 'User not found',
  USER_DEACTIVATE_HIMSELF: 'You cannot deactivate yourself',
  USER_DELETE_HIMSELF: 'You cannot delete yourself',
  USER_EDIT_HIMSELF: 'You have changed prohibited values',
  // auth
  INCORRECT_PASSWORD: 'Incorrect password',
  INCORRECT_CREDENTIALS: 'Password or username are incorrect',
  MISSING_PASSWORD_ERROR: 'No password was given',
  ATTEMPT_TOO_SOON_ERROR: 'Account is currently locked. Try again later',
  TOO_MANY_ATTEMPTS_ERROR: 'Account locked due to too many failed login attempts',
  NO_SALT_VALUE_STORED_ERROR: 'Authentication not possible. No salt value stored',
  MISSING_USERNAME_ERROR: 'No username or email was given',
  USER_EXISTS_ERROR: 'A user with the given username is already registered',
  TOKEN_EXPIRED: 'Token expired',
};

export type Translations = typeof translations;
export type BackendErrorTranslations = typeof backendErrors;
