import React, { useState } from 'react';
import s from './Modal.module.sass';
import { Button } from 'react-bootstrap';
import cn from 'classnames';
import Modal, { iModalProps } from './Modal';
import { confirmAlert } from '@uplab/react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { emptyFunction, useEnterDownDocument, useOnSubmitClick } from './utils';

export interface iConfirmModalContainerProps extends Omit<iModalProps, 'isVisible'> {
  okText?: string;
  cancelText?: string;
  description?: React.ReactNode;
  onOk?: () => void;
  hideCancel?: boolean;
}

export interface iConfirmModalProps extends Omit<iConfirmModalContainerProps, 'onClose'> {
  onClose?: () => void;
}

const ConfirmModalContainer: FC<iConfirmModalContainerProps & { onCloseAfter: () => void }> = ({
  cancelText,
  okText,
  description,
  onClose,
  onOk = emptyFunction,
  onCloseAfter,
  hideCancel,
  ...props
}) => {
  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const handleClose = useOnSubmitClick({
    isSubmitting,
    setSubmitting,
    onClick: async () => {
      await onClose();
      onCloseAfter();
    },
  });

  const handleOk = useOnSubmitClick({
    isSubmitting,
    setSubmitting,
    onClick: async () => {
      await onOk();
      onCloseAfter();
    },
  });

  useEnterDownDocument(handleOk);

  return (
    <Modal isVisible={true} onClose={handleClose} {...props}>
      {description ? <div className={cn(s.modalBody, 'border-bottom')}>{description}</div> : null}
      <div className="float-end">
        {!hideCancel ? (
          <Button variant="secondary" disabled={isSubmitting} onClick={handleClose}>
            {cancelText ?? t('no')}
          </Button>
        ) : null}{' '}
        <Button onClick={handleOk} disabled={isSubmitting}>
          {okText ?? t('yes')}
        </Button>
      </div>
    </Modal>
  );
};

const ConfirmModal = ({ onClose = emptyFunction, onOk, ...props }: iConfirmModalProps): void => {
  return confirmAlert({
    customUI: ({ onClose: onCloseAfter }) => {
      return <ConfirmModalContainer onOk={onOk} onClose={onClose} onCloseAfter={onCloseAfter} {...props} />;
    },
  });
};

export default ConfirmModal;
